import React, {useContext, useState} from 'react';
import {UserContext} from '../../utils/UserContext';
import ApplyJobPopup from '../Popups/ApplyJobPopup';
import GoogleModal from '../Popups/GoogleModal';
import {useLocation, useNavigate} from 'react-router-dom';
import {hasCreatedProfile, isJobSeeker, userExists, user_type} from '../../utils/variables/userVariables';
import {MoreVert, OpenInNew} from '@mui/icons-material';
import {ROUTES} from '../../utils/ROUTES';
import Cookies from 'js-cookie';
import {COOKIES, COOKIE_EXPIRY_TIME} from '../../utils/cookie-names';
import {Button, Checkbox, Dropdown, Modal, notification} from 'antd';
import {deleteJobApi, updateUniqueExternalLinkClicksApi} from '../../api/jobsApi';
import {Alert} from '@mui/material';
import {DeleteOutlined, EditOutlined, EyeOutlined} from '@ant-design/icons';
import {triggerFetchingJobsAfterCloseAtom} from '../../utils/atoms';
import {useAtom} from 'jotai';
import {permColors} from '../../utils/colors';

export default function ApplyJobButton({job, flexDirection = ''}) {
	const [currentUser] = useContext(UserContext);
	const [applyPopUp, setApplyPopUp] = useState(false);
	const [popup, setPopup] = useState(false);
	const navigate = useNavigate();
	const location = useLocation();
	const [modalShow, setModalShow] = useState(false);
	const [checked, setChecked] = useState(false);
	const [isDeleting, setIsDeleting] = useState(false);
	const [, setTriggerFetchingJobsAfterClose] = useAtom(triggerFetchingJobsAfterCloseAtom);
	const [currentJobClosed, setCurrentJobClosed] = useState(false);

	function openPopup() {
		setPopup(true);
	}

	function closeApplyPopup() {
		setApplyPopUp(false);
	}

	function authenticateLoginAction() {
		Cookies.set(COOKIES.current_location, location.pathname, COOKIE_EXPIRY_TIME.FIFTEEN_MINS);
		Cookies.set(COOKIES.apply_job_redirect, job.id, COOKIE_EXPIRY_TIME.FIFTEEN_MINS);
		navigate(ROUTES.AUTHENTICATE);
	}

	async function applyJob() {
		if (job?.use_external_link) {
			const url = job?.external_link.startsWith('http') ? job?.external_link : `https://${job?.external_link}`;

			// Check if this link has been clicked before using localStorage
			const clickedLinks = JSON.parse(localStorage.getItem('clickedExternalLinks') || '{}');

			if (!clickedLinks[job.id]) {
				// Only update if this job hasn't been clicked before
				await updateUniqueExternalLinkClicksApi(job.id);
				// Mark this job as clicked
				clickedLinks[job.id] = true;
				localStorage.setItem('clickedExternalLinks', JSON.stringify(clickedLinks));
			}

			window.open(url, '_blank');
			return;
		}

		if (!userExists(currentUser)) {
			openPopup();
			return;
		}

		if (!hasCreatedProfile(currentUser)) {
			Cookies.set(COOKIES.apply_job_redirect, job.id, COOKIE_EXPIRY_TIME.FIFTEEN_MINS);
			Cookies.set(COOKIES.current_location, location.pathname, COOKIE_EXPIRY_TIME.FIFTEEN_MINS);
			navigate(ROUTES.EDIT_PROFILE);
			return;
		}
		if (job?.applicants.includes(currentUser.id)) {
			openPopup();
			return;
		}
		setApplyPopUp(true);
	}

	function handleModalClose() {
		setModalShow(false);
	}

	const deleteJob = async (id) => {
		handleModalClose();
		setIsDeleting(true);
		const res = await deleteJobApi(id);
		if (res === true) {
			notification.success({
				message: 'Job closed',
				description: 'The job has been closed successfully.',
				duration: 5,
			});
			setTriggerFetchingJobsAfterClose(true);
			setCurrentJobClosed(true);
			setChecked(false);
		} else {
			setIsDeleting(false);
			notification.error({
				message: 'Error',
				description: 'Failed to close job. Please try again.',
				duration: 5,
			});
		}
	};

	function authenticatedButtons() {
		if (currentUser.id === job?.created_by) {
			return (
				<Dropdown
					menu={{
						items: [
							{
								key: '1',
								label: (
									<div className="flex items-center gap-2">
										<EditOutlined /> Edit {job?.completed ? ' (Closed)' : ''}
									</div>
								),
								onClick: () => navigate(`/edit-job/${job.id}`),
								disabled: job?.completed || currentJobClosed,
							},
							{
								key: '2',
								label: (
									<div className="flex items-center gap-2">
										<EyeOutlined /> View all applications
									</div>
								),
								onClick: () => navigate(`/applicants/${job.id}`),
							},
							{
								key: '3',
								label: (
									<div className="flex items-center gap-2">
										<DeleteOutlined /> Close Job {job?.completed ? ' (Closed)' : ''}
									</div>
								),
								danger: true,
								onClick: () => setModalShow(true),
								disabled: job?.completed || currentJobClosed,
							},
						],
					}}>
					<Button icon={<MoreVert />} type="text" />
				</Dropdown>
			);
		} else if (isJobSeeker(currentUser)) {
			const hasApplied = job?.applicants.includes(currentUser.id);
			if (!hasApplied) {
				return (
					<div className="controller">
						<div className="user">
							{job?.is_indeed_job !== true ? (
								<Button type="primary" className="rounded-md min-h-fit" onClick={applyJob} icon={job?.use_external_link ? <OpenInNew className="text-base" /> : null}>
									Apply
								</Button>
							) : (
								<a href={job?.indeed_job_url} target="_blank" className="linkButtonLinkBlue min-h-fit flex items-center gap-1 w-fit" rel="noreferrer">
									Apply <OpenInNew style={{fontSize: '1.2em'}} />
								</a>
							)}
						</div>
					</div>
				);
			} else {
				return (
					<div className="controller">
						<div className="user">
							<Button className="rounded-md min-h-fit" disabled>
								Applied
							</Button>
						</div>
					</div>
				);
			}
		}
		if (!currentUser?.profile) {
			return (
				<div className="controller">
					<div className="user">
						<Button className="rounded-md min-h-fit" type="primary" onClick={applyJob} icon={job?.use_external_link ? <OpenInNew className="text-base" /> : null}>
							Apply
						</Button>
					</div>
				</div>
			);
		}
	}

	return (
		<>
			<ApplyJobPopup
				user={currentUser}
				job={job}
				showPopup={applyPopUp}
				closePopup={closeApplyPopup}
				title={'Apply for this job'}
				textArea={true}
				buttonText={'Apply'}
				disabledButtonText={'Sent'}
			/>
			<GoogleModal
				openGoogle={popup}
				dialogTitle="Login to Apply"
				dialogContent="You need to login to apply for this job"
				button1="Login"
				closeGoogle={() => setPopup(false)}
				button1function={authenticateLoginAction}
			/>
			<Modal
				title="Close job"
				open={modalShow}
				onCancel={handleModalClose}
				footer={[
					<Button key="cancel" onClick={handleModalClose}>
						No
					</Button>,
					<Button key="submit" onClick={() => deleteJob(job.id)} danger disabled={!checked || isDeleting}>
						Yes
					</Button>,
				]}>
				<p>
					Do you wish to continue to close this job?{' '}
					<Alert className="mt-1 font-medium" severity="error">
						This action cannot be undone. Once closed, the job will no longer be visible to applicants and you will not be able to re-open it.
					</Alert>
				</p>
				<Checkbox type="checkbox" className="flex items-top gap-1 mt-2" checked={checked} onChange={() => setChecked(!checked)}>
					I understand that this action cannot be undone.
				</Checkbox>
			</Modal>
			{authenticatedButtons()}
		</>
	);
}
