import React from 'react';
import {useState} from 'react';
import Login from '../../components/AuthComponents/Login';
import Signup from '../../components/AuthComponents/Signup';
import './AuthPage.scss';
import {Link} from 'react-router-dom';
import {useParams} from 'react-router-dom';
import {Helmet} from 'react-helmet';
import {useMediaQuery} from '@material-ui/core';
import {tailwindPermColors} from '../../utils/colors';
import {Carousel} from 'react-bootstrap';
import {FullLogoSmall, FullLogoSmallNav, LightLogo} from '../../components/Logos/Logo';
import {useAtom} from 'jotai';
import {isSignupAtom} from '../../utils/atoms';
import {Button} from 'antd';
import {ArrowRightOutlined} from '@ant-design/icons';

export default function AuthPage() {
	const [isSignup, setIsSignup] = useAtom(isSignupAtom);

	const matches = useMediaQuery('(max-width: 720px)');

	const carouselItems = [
		{
			title: 'Discover Your Next Career Opportunity',
			description: 'Jumpstart your career with top companies in around the world. Explore diverse job openings and find your perfect match in a workplace that values talent and innovation.',
		},
		{
			title: 'Hire Top Filipino Talent',
			description:
				'Connect with skilled professionals from the Philippines. Our platform offers access to a vast pool of candidates with a high proficiency in English and strong work ethic, ready to contribute to your success.',
		},
		{
			title: 'Expand Your Professional Network',
			description:
				'Engage with the vibrant community of professionals and industry leaders in the Philippines. Enhance your career or business by leveraging connections and opportunities within our extensive network.',
		},
	];

	return (
		<div className="auth-page-container">
			<Helmet>
				<title>Log in or Sign up | Permworks</title>
				<meta name="description" content="Log in or Sign up to Permworks to get started on your journey to find a job or a candidate for your job posting." />
			</Helmet>
			<div className="left-side">
				<FullLogoSmallNav className="w-full max-w-[500px] px-3 mt-4" />
				<div className="middleContainer rounded-lg mt-2">
					{isSignup ? <Signup /> : <Login />}
					<p className="text-sm text-center mb-1 mt-4 text-charcoal">{isSignup ? 'Have an account already?' : "Don't have an account?"}</p>
					<Button iconPosition="end" className="w-full mb-3" onClick={() => setIsSignup(!isSignup)}>
						{isSignup ? 'Sign in' : 'Sign up'}
					</Button>
				</div>
			</div>
			{!matches && (
				<div className="right-side rounded-lg">
					<Carousel interval={7000} className="w-full h-full px-2" controls={false} pause={false} indicators={true}>
						{carouselItems.map((item, index) => (
							<Carousel.Item key={index} className="w-full">
								<div className="w-full flex justify-center text-center">
									<div className="max-w-[600px]">
										<div className="flex justify-center">
											<LightLogo textVisibility={false} />
										</div>
										<h3 className={tailwindPermColors.white + ' font-semibold text-3xl mt-8 mb-2'}>{item.title}</h3>
										<p>{item.description}</p>
									</div>
								</div>
							</Carousel.Item>
						))}
					</Carousel>
				</div>
			)}
		</div>
	);
}
