import React, {useContext, useState, useRef, useEffect} from 'react';
import Footer from '../../components/Footer/Footer';
import Navbar from '../../components/Navbars/Navbar2';
import {UserContext} from '../../utils/UserContext';
import './EditProfile.scss';
import uploadCircle from '../../assets/images/uploadCircle.png';
import {Helmet} from 'react-helmet';

import {
	addEducationApi,
	addPortfolioApi,
	addSkillsApi,
	addWorkExperienceApi,
	deleteEducationApi,
	deletePortfolioApi,
	deleteSkillsApi,
	deleteWorkExperienceApi,
	editProfileApi,
	editProfileImage,
	getEducationApi,
	getPortfolioApi,
	getSkillsApi,
	getWorkExperienceApi,
	patchEducationApi,
	patchPortfolioApi,
	patchWorkExperienceApi,
} from '../../api/profileApi';
import Loading from '../../components/Loaders/Loading';
import {capitalizeFirstLetter, textFormatter} from '../../utils/TextAlterations';
import {allMonths, allYears} from '../../utils/arrayData/DateArrays';
import {countries} from '../../utils/arrayData/CountryArray';
import {categoryOptions, jobSkills, serviceType} from '../../utils/arrayData/PortfolioArrays';
import NewPasswordPopup from '../../components/AuthComponents/NewPasswordPopup';
import {Add, Check, Close, Delete, Edit, Place, WorkRounded} from '@material-ui/icons';
import {capitalize} from '@mui/material';
import {Form} from 'react-bootstrap';
import {Alert, Button, Card, notification, Popconfirm, Spin, Tag, Upload} from 'antd';
import {EditOutlined, LockOutlined, CloseOutlined, CheckOutlined} from '@ant-design/icons';
import {Input as AntInput, Select as AntSelect, Form as AntForm} from 'antd';
import {permColors} from '../../utils/colors';
import {PlaceOutlined} from '@mui/icons-material';

export default function EditProfile() {
	const countryList = countries;
	const [currentUser, setCurrentUser] = useContext(UserContext);
	const [edit, setEdit] = useState(false);

	const fileInputRef = useRef(null);
	const [loading, setLoading] = useState(false);
	const [edit2, setEdit2] = useState(false);
	const [workExperience, setWorkExperience] = useState([]);

	const [selectedWorkExperienceIndex, setSelectedWorkExperienceIndex] = useState(null);
	const months = allMonths;
	const years = allYears;
	const categories = categoryOptions;
	const services = serviceType;
	const skillsList = jobSkills;

	const [newJobForm, setNewJobForm] = useState(false);

	const [education, setEducation] = useState([]);
	const [newEducationForm, setNewEducationForm] = useState(false);
	const [edit3, setEdit3] = useState(false);
	const [selectedEducationIndex, setSelectedEducationIndex] = useState(null);

	const [editPortfolio, setEditPortfolio] = useState(false);
	const [portfolio, setPortfolio] = useState([]);
	const [newPortfolioForm, setNewPortfolioForm] = useState(false);
	const [selectedPortfolioIndex, setSelectedPortfolioIndex] = useState(null);

	const [skills, setSkills] = useState([]);
	const [newSkillName, setNewSkillName] = useState('');
	const [skillsEdit, setSkillsEdit] = useState(false);
	const [skillsError, setSkillsError] = useState(false);

	const [tagLoading, setTagLoading] = useState(false);
	const [portfolioLoading, setPortfolioLoading] = useState(false);
	const [workExperienceLoading, setWorkExperienceLoading] = useState(false);
	const [educationLoading, setEducationLoading] = useState(false);

	const [popup, setPopup] = useState(false);
	const resetPasswordButton = () => {
		openPopup();
	};
	function openPopup() {
		setPopup(true);
	}
	function closePopup() {
		setPopup(false);
	}

	useEffect(() => {
		if (currentUser?.profile) {
			async function getProfile() {
				setLoading(true);
				const res = await getWorkExperienceApi();
				const res2 = await getEducationApi();
				const res3 = await getPortfolioApi();
				const res4 = await getSkillsApi();
				setSkills(res4);
				setWorkExperience(res);
				setEducation(res2);
				setPortfolio(res3);
				setLoading(false);
			}
			getProfile();
		}
	}, [currentUser]);

	const successNotification = (message, description) => {
		notification.success({
			message: message,
			description: description,
			duration: 5,
		});
	};

	const errorNotification = (message, description) => {
		notification.error({
			message: message,
			description: description,
			duration: 5,
		});
	};

	async function addWorkExperience(values) {
		setWorkExperienceLoading(true);
		try {
			const res = await addWorkExperienceApi(values);
			setNewJobForm(false);
			console.log(res);
			getWorkExperience();
			successNotification('Work experience added successfully', 'Your work experience has been added successfully');
		} catch (err) {
			console.log(err);
			errorNotification('Error', 'Something went wrong');
		} finally {
			setWorkExperienceLoading(false);
		}
	}

	async function addEducation(values) {
		setEducationLoading(true);
		try {
			const res = await addEducationApi(values);
			setNewEducationForm(false);
			console.log(res);
			getEducation();
			setEducationLoading(false);
			successNotification('Education added successfully', 'Your education has been added successfully');
		} catch (err) {
			console.log(err);
			errorNotification('Error', 'Something went wrong');
		} finally {
			setEducationLoading(false);
		}
	}

	async function addPortfolio(values) {
		setPortfolioLoading(true);
		try {
			const res = await addPortfolioApi(values);
			setNewPortfolioForm(false);
			console.log(res);
			getPortfolio();
			setPortfolioLoading(false);
			successNotification('Portfolio added successfully', 'Your portfolio item has been added successfully');
		} catch (err) {
			console.log(err);
			errorNotification('Error', 'Something went wrong');
		} finally {
			setPortfolioLoading(false);
		}
	}

	async function addSkills() {
		if (skills.some((skill) => skill.name === newSkillName)) {
			console.log('Skill already exists in the array.');
			setSkillsError(true);
		} else {
			setSkillsError(false);
			setTagLoading(true);
			const res = await addSkillsApi(newSkillName);
			console.log(res);
			getSkills();
			setTagLoading(false);
		}
	}

	async function getEducation() {
		const res = await getEducationApi();
		setEducation(res);
	}

	async function getWorkExperience() {
		const res = await getWorkExperienceApi();
		setWorkExperience(res);
	}

	async function getPortfolio() {
		const res = await getPortfolioApi();
		setPortfolio(res);
	}

	async function getSkills() {
		const res = await getSkillsApi();
		console.log(res);
		setSkills(res);
	}

	const handleImageChange = async (e) => {
		if (e.target.files && e.target.files[0]) {
			setLoading(true);
			const res = await editProfileImage(e.target.files[0]);
			console.log(res);
			window.location.reload();
		}
	};
	async function updateProfile(values) {
		try {
			const res = await editProfileApi(values);
			setEdit(false);
			console.log(res);
			window.location.reload();
		} catch (err) {
			console.log(err);
		}
	}

	async function patchWorkExperience(values) {
		setWorkExperienceLoading(true);
		try {
			const res = await patchWorkExperienceApi(workExperience[selectedWorkExperienceIndex].id, values);
			setEdit2(false);
			console.log(res);
			setSelectedWorkExperienceIndex(null);
			getWorkExperience();
			successNotification('Work experience updated successfully', 'Your work experience has been updated successfully');
		} catch (err) {
			console.log(err);
			errorNotification('Error', 'Something went wrong');
		} finally {
			setWorkExperienceLoading(false);
		}
	}

	async function patchEducation(values) {
		setEducationLoading(true);
		try {
			const res = await patchEducationApi(education[selectedEducationIndex].id, values);
			setEdit3(false);
			console.log(res);
			setSelectedEducationIndex(null);
			getEducation();
			successNotification('Education updated successfully', 'Your education has been updated successfully');
		} catch (err) {
			console.log(err);
			errorNotification('Error', 'Something went wrong');
		} finally {
			setEducationLoading(false);
		}
	}

	async function patchPortfolio(values) {
		setPortfolioLoading(true);
		try {
			const res = await patchPortfolioApi(portfolio[selectedPortfolioIndex].id, values);
			setEditPortfolio(false);
			console.log(res);
			setSelectedPortfolioIndex(null);
			getPortfolio();
			successNotification('Portfolio updated successfully', 'Your portfolio item has been updated successfully');
		} catch (err) {
			console.log(err);
			errorNotification('Error', 'Something went wrong');
		} finally {
			setPortfolioLoading(false);
		}
	}

	async function deleteWorkExperience(id) {
		setWorkExperienceLoading(true);
		try {
			const res = await deleteWorkExperienceApi(id);
			console.log(res);
			getWorkExperience();
			successNotification('Work experience deleted successfully', 'Your work experience has been deleted successfully');
		} catch (err) {
			console.log(err);
			errorNotification('Error', 'Something went wrong');
		} finally {
			setWorkExperienceLoading(false);
		}
	}

	async function deleteEducation(id) {
		setEducationLoading(true);
		try {
			const res = await deleteEducationApi(id);
			console.log(res);
			getEducation();
			successNotification('Education deleted successfully', 'Your education has been deleted successfully');
		} catch (err) {
			console.log(err);
			errorNotification('Error', 'Something went wrong');
		} finally {
			setEducationLoading(false);
		}
	}

	async function deletePortfolio(id) {
		setPortfolioLoading(true);
		try {
			const res = await deletePortfolioApi(id);
			console.log(res);
			getPortfolio();
			successNotification('Portfolio deleted successfully', 'Your portfolio item has been deleted successfully');
		} catch (err) {
			console.log(err);
			errorNotification('Error', 'Something went wrong');
		} finally {
			setPortfolioLoading(false);
		}
	}

	async function deleteSkills(id) {
		setTagLoading(true);
		try {
			const res = await deleteSkillsApi(id);
			console.log(res);
			getSkills();
		} catch (err) {
			console.log(err);
		} finally {
			setTagLoading(false);
		}
	}

	return (
		<div className="edit-profile-container min-h-screen flex flex-col" style={{backgroundColor: permColors.backgroundGreyBlue}}>
			<Helmet>
				<title>Edit Profile | Permworks</title>
				<meta name="description" content="Update your Permworks profile. Keep your skills, experience, and other details up to date." />
			</Helmet>
			<Navbar />
			<div className="edit-profile-outer">
				<div className="editProfileContainer">
					<div className="left-side">
						<div className="container">
							<h1 className="md:text-left p-0 text-xl font-semibold mb-1">Profile page</h1>
							<Card className="mb-2">
								<div className="flex items-center gap-3">
									<Form.Control type="file" ref={fileInputRef} style={{display: 'none'}} onChange={(e) => handleImageChange(e)} />
									<Upload
										showUploadList={false}
										accept="image/*"
										beforeUpload={(file) => {
											handleImageChange({target: {files: [file]}});
											return false;
										}}>
										<img
											src={currentUser?.profile?.profile_image ? currentUser?.profile?.profile_image : uploadCircle}
											alt="Circle profile"
											className="w-16 h-16 shadow-sm rounded-full cursor-pointer hover:shadow-md hover:opacity-80"
										/>
									</Upload>
									<div className="flex flex-col gap-1">
										<h4 className="text-base font-semibold">
											{capitalize(currentUser?.profile?.first_name)} {capitalize(currentUser?.profile?.last_name)}
										</h4>
										<p className="text-sm ">{currentUser?.email}</p>
										<Tag className="flex gap-2 items-center text-sm w-fit mt-1">
											<PlaceOutlined className="text-sm" /> {currentUser?.profile?.country ?? 'Unknown'}
										</Tag>
									</div>
								</div>
							</Card>

							{!edit && (
								<div className="flex flex-col gap-1">
									<Button className="flex items-center gap-2 justify-center" onClick={() => setEdit(true)} icon={<EditOutlined />}>
										Edit
									</Button>
									{currentUser?.auth_provider === 'email' && (
										<Button type="primary" onClick={resetPasswordButton} icon={<LockOutlined />}>
											Change Password
										</Button>
									)}
								</div>
							)}
							{edit && (
								<AntForm layout="vertical" onFinish={updateProfile}>
									<div className="edit-container">
										<AntForm.Item
											label="First Name"
											name="first_name"
											initialValue={currentUser?.profile?.first_name}
											rules={[{required: true, message: 'Please input your first name!'}]}>
											<AntInput />
										</AntForm.Item>

										<AntForm.Item
											label="Last Name"
											name="last_name"
											initialValue={currentUser?.profile?.last_name}
											rules={[{required: true, message: 'Please input your last name!'}]}>
											<AntInput />
										</AntForm.Item>

										<AntForm.Item label="Country" name="country" initialValue={currentUser?.profile?.country} rules={[{required: true, message: 'Please select your country!'}]}>
											<AntSelect showSearch filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
												<AntSelect.Option value="">Select country</AntSelect.Option>
												{countryList.map((country, index) => (
													<AntSelect.Option key={index} value={country}>
														{country}
													</AntSelect.Option>
												))}
											</AntSelect>
										</AntForm.Item>

										<div className="buttons">
											<Button type="primary" htmlType="submit" icon={<CheckOutlined />} />
											<Button onClick={() => setEdit(false)} icon={<CloseOutlined />} />
										</div>
									</div>
								</AntForm>
							)}
						</div>

						{/*  Skills Tags =====================================================================================  */}

						<div className="container">
							<Spin spinning={tagLoading}>
								<h3 className="flex items-center gap-1">
									Skills <Add className="plusIcon" onClick={() => setSkillsEdit(true)} />
								</h3>

								<div className="flex flex-wrap gap-2 my-2">
									{skills?.map((skill, index) => {
										return (
											<Tag key={index} closable onClose={() => deleteSkills(skill.id)} color="blue" className="mr-0 flex gap-2">
												{skill?.name}
											</Tag>
										);
									})}
								</div>
							</Spin>
							{skillsError && <Alert message="Skill tag already exists" type="error" showIcon className="mb-2 rounded-md" />}

							{skillsEdit && (
								<div className="edit-container">
									<AntSelect
										name="skills"
										id="skills"
										placeholder="Select skill"
										onChange={(value) => setNewSkillName(value)}
										options={[
											{value: '', label: 'Select skill', disabled: true},
											...skillsList.map((skill) => ({
												value: skill,
												label: skill,
											})),
										]}
										showSearch
									/>
									<div className="buttons">
										<div onClick={addSkills}>
											<Check className="edit-icon" />
										</div>{' '}
										<div
											onClick={() => {
												setSkillsEdit(false);
												setSkillsError(false);
											}}>
											<Close className="edit-icon" />
										</div>
									</div>
								</div>
							)}
						</div>
					</div>

					{/*  Portfolio =====================================================================================  */}

					<div className="right-side">
						<div className="container">
							<h3 className="flex items-center gap-1">
								Portfolio
								<Add className={`plusIcon ${newPortfolioForm ? 'rotate-45' : ''}`} onClick={newPortfolioForm ? () => setNewPortfolioForm(false) : () => setNewPortfolioForm(true)} />
							</h3>
							<Spin spinning={portfolioLoading}>
								{newPortfolioForm && (
									<div className="edit-container">
										<AntForm onFinish={addPortfolio} layout="vertical">
											<AntForm.Item label="Portfolio Title" name="title" rules={[{required: true, message: 'Please enter portfolio title'}]}>
												<AntInput placeholder="Portfolio title" />
											</AntForm.Item>

											<AntForm.Item label="Portfolio Description" name="description" rules={[{required: true, message: 'Please enter portfolio description'}]}>
												<AntInput.TextArea rows={4} placeholder="Portfolio description" />
											</AntForm.Item>

											<AntForm.Item label="Service Type" name="service_type" rules={[{required: true, message: 'Please select service type'}]}>
												<AntSelect placeholder="Select service type">
													{services.map((serviceType, index) => (
														<AntSelect.Option key={index} value={serviceType}>
															{serviceType}
														</AntSelect.Option>
													))}
												</AntSelect>
											</AntForm.Item>

											<AntForm.Item label="Portfolio Category" name="category" rules={[{required: true, message: 'Please select portfolio category'}]}>
												<AntSelect placeholder="Select portfolio category">
													{categories.map((portfolioCategory, index) => (
														<AntSelect.Option key={index} value={portfolioCategory}>
															{portfolioCategory}
														</AntSelect.Option>
													))}
												</AntSelect>
											</AntForm.Item>

											<AntForm.Item label="Completion Date" required className="mb-0">
												<AntForm.Item
													name="completion_month"
													rules={[{required: true, message: 'Please select completion month'}]}
													style={{display: 'inline-block', width: 'calc(50% - 8px)', marginRight: '16px'}}>
													<AntSelect placeholder="Select month">
														{months.map((month, index) => (
															<AntSelect.Option key={index} value={month}>
																{month}
															</AntSelect.Option>
														))}
													</AntSelect>
												</AntForm.Item>
												<AntForm.Item
													name="completion_year"
													rules={[{required: true, message: 'Please select completion year'}]}
													style={{display: 'inline-block', width: 'calc(50% - 8px)'}}>
													<AntSelect placeholder="Select year">
														{years.map((year, index) => (
															<AntSelect.Option key={index} value={year}>
																{year}
															</AntSelect.Option>
														))}
													</AntSelect>
												</AntForm.Item>
											</AntForm.Item>

											<div className="buttons">
												<Button icon={<CheckOutlined />} htmlType="submit">
													Save
												</Button>
												<Button icon={<CloseOutlined />} onClick={() => setNewPortfolioForm(false)}>
													Cancel
												</Button>
											</div>
										</AntForm>
									</div>
								)}
								{!editPortfolio &&
									portfolio?.map((work, index) => {
										return (
											<div className="workExperience" key={index}>
												<h4>{work?.title}</h4>
												<h5>{work?.description}</h5>
												<h5>{work?.service_type}</h5>
												<h5>{work?.category}</h5>
												<h5>
													{work?.completion_month &&
														`Completed ${work?.completion_month && work?.completion_year ? `${work?.completion_month} ${work?.completion_year}` : ''}`}
												</h5>
												<div className="buttons">
													<Edit
														className="edit-icon"
														onClick={() => {
															setSelectedPortfolioIndex(index);
															setEditPortfolio(true);
														}}
													/>
													<Popconfirm title="Are you sure you want to delete this portfolio item?" onConfirm={() => deletePortfolio(work?.id)}>
														<Delete className="edit-icon" />
													</Popconfirm>
												</div>
											</div>
										);
									})}
								{editPortfolio && (
									<div>
										{selectedPortfolioIndex !== null && (
											<AntForm onFinish={patchPortfolio} layout="vertical" className="mt-4">
												<AntForm.Item
													label="Portfolio Title"
													name="title"
													initialValue={portfolio[selectedPortfolioIndex]?.title}
													rules={[{required: true, message: 'Please enter portfolio title'}]}>
													<AntInput placeholder="Portfolio title" />
												</AntForm.Item>

												<AntForm.Item
													label="Portfolio Description"
													name="description"
													initialValue={portfolio[selectedPortfolioIndex]?.description}
													rules={[{required: true, message: 'Please enter portfolio description'}]}>
													<AntInput.TextArea placeholder="Portfolio description" />
												</AntForm.Item>

												<AntForm.Item
													label="Service Type"
													name="service_type"
													initialValue={portfolio[selectedPortfolioIndex]?.service_type}
													rules={[{required: true, message: 'Please select service type'}]}>
													<AntSelect placeholder="Select service type">
														{services.map((serviceType, index) => (
															<AntSelect.Option key={index} value={serviceType}>
																{serviceType}
															</AntSelect.Option>
														))}
													</AntSelect>
												</AntForm.Item>

												<AntForm.Item
													label="Portfolio Category"
													name="category"
													initialValue={portfolio[selectedPortfolioIndex]?.category}
													rules={[{required: true, message: 'Please select portfolio category'}]}>
													<AntSelect placeholder="Select portfolio category">
														{categories.map((portfolioCategory, index) => (
															<AntSelect.Option key={index} value={portfolioCategory}>
																{portfolioCategory}
															</AntSelect.Option>
														))}
													</AntSelect>
												</AntForm.Item>

												<AntForm.Item
													label="Completion Month"
													name="completion_month"
													initialValue={portfolio[selectedPortfolioIndex]?.completion_month}
													rules={[{required: true, message: 'Please select completion month'}]}>
													<AntSelect placeholder="Select month">
														{months.map((month, index) => (
															<AntSelect.Option key={index} value={month}>
																{month}
															</AntSelect.Option>
														))}
													</AntSelect>
												</AntForm.Item>

												<AntForm.Item
													label="Completion Year"
													name="completion_year"
													initialValue={portfolio[selectedPortfolioIndex]?.completion_year}
													rules={[{required: true, message: 'Please select completion year'}]}>
													<AntSelect placeholder="Select year">
														{years.map((year, index) => (
															<AntSelect.Option key={index} value={year}>
																{year}
															</AntSelect.Option>
														))}
													</AntSelect>
												</AntForm.Item>

												<div className="buttons">
													<Button icon={<CheckOutlined />} htmlType="submit">
														Save
													</Button>
													<Button
														icon={<CloseOutlined />}
														onClick={() => {
															setEditPortfolio(false);
															setSelectedWorkExperienceIndex(null);
														}}>
														Cancel
													</Button>
												</div>
											</AntForm>
										)}
									</div>
								)}
							</Spin>
						</div>

						{/*  Work experience ========================================================================== */}

						<div className="container">
							<h3 className="flex items-center gap-1">
								Work Experience
								<Add className={`plusIcon ${newJobForm ? 'rotate-45' : ''}`} onClick={newJobForm ? () => setNewJobForm(false) : () => setNewJobForm(true)} />
							</h3>
							<Spin spinning={workExperienceLoading}>
								{newJobForm && (
									<div className="edit-container">
										<AntForm onFinish={addWorkExperience} layout="vertical" className="mt-4">
											<AntForm.Item label="Company name" name="company_name" rules={[{required: true, message: 'Please enter company name'}]}>
												<AntInput placeholder="Company name" />
											</AntForm.Item>

											<AntForm.Item label="Position" name="position_title" rules={[{required: true, message: 'Please enter position'}]}>
												<AntInput placeholder="Position" />
											</AntForm.Item>

											<AntForm.Item label="Start date" required className="mb-0">
												<AntForm.Item
													name="from_month"
													rules={[{required: true, message: 'Please select start month'}]}
													style={{display: 'inline-block', width: 'calc(50% - 8px)', marginRight: '16px'}}>
													<AntSelect placeholder="Select month">
														{months.map((month, index) => (
															<AntSelect.Option key={index} value={month}>
																{month}
															</AntSelect.Option>
														))}
													</AntSelect>
												</AntForm.Item>
												<AntForm.Item
													name="from_year"
													rules={[{required: true, message: 'Please select start year'}]}
													style={{display: 'inline-block', width: 'calc(50% - 8px)'}}>
													<AntSelect placeholder="Select year">
														{years.map((year, index) => (
															<AntSelect.Option key={index} value={year}>
																{year}
															</AntSelect.Option>
														))}
													</AntSelect>
												</AntForm.Item>
											</AntForm.Item>

											<AntForm.Item label="End date" className="mb-0">
												<AntForm.Item name="to_month" style={{display: 'inline-block', width: 'calc(50% - 8px)', marginRight: '16px'}}>
													<AntSelect placeholder="Select month">
														{months.map((month, index) => (
															<AntSelect.Option key={index} value={month}>
																{month}
															</AntSelect.Option>
														))}
													</AntSelect>
												</AntForm.Item>
												<AntForm.Item name="to_year" style={{display: 'inline-block', width: 'calc(50% - 8px)'}}>
													<AntSelect placeholder="Select year">
														{years.map((year, index) => (
															<AntSelect.Option key={index} value={year}>
																{year}
															</AntSelect.Option>
														))}
													</AntSelect>
												</AntForm.Item>
											</AntForm.Item>

											<AntForm.Item label="Country" name="country" rules={[{required: true, message: 'Please select country'}]}>
												<AntSelect placeholder="Select country">
													{countryList.map((country, index) => (
														<AntSelect.Option key={index} value={country}>
															{country}
														</AntSelect.Option>
													))}
												</AntSelect>
											</AntForm.Item>

											<AntForm.Item label="Description" name="description" rules={[{required: true, message: 'Please enter description'}]}>
												<AntInput.TextArea rows={6} />
											</AntForm.Item>

											<div className="buttons">
												<Button icon={<CheckOutlined />} htmlType="submit">
													Save
												</Button>
												<Button icon={<CloseOutlined />} onClick={() => setNewJobForm(false)}>
													Cancel
												</Button>
											</div>
										</AntForm>
									</div>
								)}

								{!edit2 &&
									workExperience?.map((work, index) => {
										return (
											<div className="workExperience" key={index}>
												<h4>
													{' '}
													<strong>Company name - </strong>
													{capitalizeFirstLetter(work?.company_name)}
												</h4>
												<h5>
													{' '}
													<strong>Position - </strong>
													{capitalizeFirstLetter(work?.position_title)}
												</h5>
												<h5>{work?.country}</h5>
												<h5>
													{work?.from_year} {work?.from_month} -{work?.to_year} {work?.to_month}
												</h5>
												<p
													className="description"
													dangerouslySetInnerHTML={{
														__html: textFormatter(work?.description),
													}}
												/>
												<div className="buttons">
													<Edit
														className="edit-icon"
														onClick={() => {
															setSelectedWorkExperienceIndex(index);
															setEdit2(true);
														}}
													/>
													<Popconfirm title="Are you sure you want to delete this work experience item?" onConfirm={() => deleteWorkExperience(work?.id)}>
														<Delete className="edit-icon" />
													</Popconfirm>
												</div>
											</div>
										);
									})}
								{edit2 && (
									<div>
										{selectedWorkExperienceIndex !== null && (
											<div className="edit-container" key={selectedWorkExperienceIndex}>
												<AntForm onFinish={patchWorkExperience} layout="vertical">
													<AntForm.Item
														label="Company name"
														className="mb-1"
														name="company_name"
														initialValue={workExperience[selectedWorkExperienceIndex]?.company_name}
														rules={[{required: true, message: 'Please enter company name'}]}>
														<AntInput placeholder="Company name" required />
													</AntForm.Item>

													<AntForm.Item
														label="Position title"
														name="position_title"
														initialValue={workExperience[selectedWorkExperienceIndex]?.position_title}
														rules={[{required: true, message: 'Please enter position title'}]}>
														<AntInput placeholder="Position title" required />
													</AntForm.Item>

													<AntForm.Item
														label="Country"
														name="country"
														initialValue={workExperience[selectedWorkExperienceIndex]?.country}
														rules={[{required: true, message: 'Please select country'}]}>
														<AntSelect required>
															{countryList.map((country, index) => (
																<AntSelect.Option key={index} value={country}>
																	{country}
																</AntSelect.Option>
															))}
														</AntSelect>
													</AntForm.Item>

													<AntForm.Item
														label="Month from"
														name="from_month"
														initialValue={workExperience[selectedWorkExperienceIndex]?.from_month}
														rules={[{required: true, message: 'Please select month'}]}>
														<AntSelect required>
															{allMonths.map((month, index) => (
																<AntSelect.Option key={index} value={month}>
																	{month}
																</AntSelect.Option>
															))}
														</AntSelect>
													</AntForm.Item>

													<AntForm.Item
														label="Year from"
														name="from_year"
														initialValue={workExperience[selectedWorkExperienceIndex]?.from_year}
														rules={[{required: true, message: 'Please select year'}]}>
														<AntSelect required>
															{years.map((year, index) => (
																<AntSelect.Option key={index} value={year}>
																	{year}
																</AntSelect.Option>
															))}
														</AntSelect>
													</AntForm.Item>

													<AntForm.Item
														label="Month to"
														name="to_month"
														initialValue={workExperience[selectedWorkExperienceIndex]?.to_month}
														rules={[{required: true, message: 'Please select month'}]}>
														<AntSelect required>
															{allMonths.map((month, index) => (
																<AntSelect.Option key={index} value={month}>
																	{month}
																</AntSelect.Option>
															))}
														</AntSelect>
													</AntForm.Item>

													<AntForm.Item
														label="Year to"
														name="to_year"
														initialValue={workExperience[selectedWorkExperienceIndex]?.to_year}
														rules={[{required: true, message: 'Please select year'}]}>
														<AntSelect required>
															{years.map((year, index) => (
																<AntSelect.Option key={index} value={year}>
																	{year}
																</AntSelect.Option>
															))}
														</AntSelect>
													</AntForm.Item>

													<AntForm.Item
														label="Work description"
														name="description"
														initialValue={workExperience[selectedWorkExperienceIndex]?.description}
														rules={[{required: true, message: 'Please enter work description'}]}>
														<AntInput.TextArea rows={6} required placeholder="Work description" />
													</AntForm.Item>

													<div className="buttons">
														<Button icon={<CheckOutlined />} htmlType="submit">
															Save
														</Button>
														<Button
															icon={<CloseOutlined />}
															onClick={() => {
																setEdit2(false);
																setSelectedWorkExperienceIndex(null);
															}}>
															Cancel
														</Button>
													</div>
												</AntForm>
											</div>
										)}
									</div>
								)}
							</Spin>
						</div>

						{/* education--------------------------- ---------- -------- */}

						<div className="container">
							<h3 className="flex items-center gap-1">
								Education
								<Add className={`plusIcon ${newEducationForm ? 'rotate-45' : ''}`} onClick={newEducationForm ? () => setNewEducationForm(false) : () => setNewEducationForm(true)} />
							</h3>
							<Spin spinning={educationLoading}>
								{newEducationForm && (
									<div className="edit-container">
										<AntForm onFinish={addEducation} layout="vertical">
											<AntForm.Item label="Area of study" name="area_of_study" rules={[{required: true, message: 'Please enter area of study'}]}>
												<AntInput placeholder="Area of study" />
											</AntForm.Item>

											<AntForm.Item label="Education level" name="education_qualification" rules={[{required: true, message: 'Please select education level'}]}>
												<AntSelect placeholder="Select education level">
													<AntSelect.Option value="High school">High school</AntSelect.Option>
													<AntSelect.Option value="Bachelor">Bachelor</AntSelect.Option>
													<AntSelect.Option value="Master">Master</AntSelect.Option>
													<AntSelect.Option value="PhD">PhD</AntSelect.Option>
												</AntSelect>
											</AntForm.Item>

											<AntForm.Item label="School name" name="school_name" rules={[{required: true, message: 'Please enter school name'}]}>
												<AntInput placeholder="School name" />
											</AntForm.Item>

											<AntForm.Item label="Duration" style={{marginBottom: 0}}>
												<AntForm.Item
													name="year_from"
													rules={[{required: true, message: 'Please select start year'}]}
													style={{display: 'inline-block', width: 'calc(50% - 8px)'}}>
													<AntSelect placeholder="Start year">
														{years.map((year, index) => (
															<AntSelect.Option key={index} value={year}>
																{year}
															</AntSelect.Option>
														))}
													</AntSelect>
												</AntForm.Item>
												<AntForm.Item
													name="year_to"
													rules={[{required: true, message: 'Please select end year'}]}
													style={{display: 'inline-block', width: 'calc(50% - 8px)', margin: '0 0 0 16px'}}>
													<AntSelect placeholder="End year">
														{years.map((year, index) => (
															<AntSelect.Option key={index} value={year}>
																{year}
															</AntSelect.Option>
														))}
													</AntSelect>
												</AntForm.Item>
											</AntForm.Item>

											<AntForm.Item label="Education description" name="description" rules={[{required: true, message: 'Please enter education description'}]}>
												<AntInput.TextArea rows={6} placeholder="Education description" />
											</AntForm.Item>

											<div className="buttons">
												<Button icon={<CheckOutlined />} htmlType="submit">
													Save
												</Button>
												<Button icon={<CloseOutlined />} onClick={() => setNewEducationForm(false)}>
													Cancel
												</Button>
											</div>
										</AntForm>
									</div>
								)}

								{!edit3 && (
									<div>
										{education?.map((item, index) => {
											return (
												<div className="workExperience" key={index}>
													<h4>
														<strong>Area of study - </strong>
														{item?.area_of_study && capitalizeFirstLetter(item?.area_of_study)}
													</h4>
													<h5>
														{' '}
														<strong>Institution - </strong>
														{item?.school_name && capitalizeFirstLetter(item?.school_name)}
													</h5>
													<h5>{item?.education_qualification}</h5>
													<h5>
														{item?.year_from} - {item?.year_to}
													</h5>
													<p
														className="description"
														dangerouslySetInnerHTML={{
															__html: textFormatter(item?.description),
														}}
													/>
													<div className="buttons">
														<Edit
															className="edit-icon"
															onClick={() => {
																setSelectedEducationIndex(index);
																setEdit3(true);
															}}
														/>
														<Popconfirm title="Are you sure you want to delete this education item?" onConfirm={() => deleteEducation(item?.id)}>
															<Delete className="edit-icon" />
														</Popconfirm>
													</div>
												</div>
											);
										})}
									</div>
								)}
								{edit3 && (
									<div>
										{selectedEducationIndex !== null && (
											<div className="edit-container" key={selectedEducationIndex}>
												<AntForm onFinish={patchEducation} layout="vertical">
													<AntForm.Item
														label="Area of study / Course name"
														name="area_of_study"
														initialValue={education[selectedEducationIndex]?.area_of_study}
														rules={[{required: true, message: 'Please enter area of study'}]}>
														<AntInput placeholder="Area of study / Course name" />
													</AntForm.Item>

													<AntForm.Item
														label="Education level"
														name="education_qualification"
														initialValue={education[selectedEducationIndex]?.education_qualification}
														rules={[{required: true, message: 'Please select education level'}]}>
														<AntSelect placeholder="Select education level">
															<AntSelect.Option value="High school">High school</AntSelect.Option>
															<AntSelect.Option value="Bachelor">Bachelor</AntSelect.Option>
															<AntSelect.Option value="Master">Master</AntSelect.Option>
															<AntSelect.Option value="PhD">PhD</AntSelect.Option>
														</AntSelect>
													</AntForm.Item>

													<AntForm.Item
														label="School name"
														name="school_name"
														initialValue={education[selectedEducationIndex]?.school_name}
														rules={[{required: true, message: 'Please enter school name'}]}>
														<AntInput placeholder="School name" />
													</AntForm.Item>

													<AntForm.Item
														label="Year from"
														name="year_from"
														initialValue={education[selectedEducationIndex]?.year_from}
														rules={[{required: true, message: 'Please select start year'}]}>
														<AntSelect placeholder="Select year">
															{years.map((year, index) => (
																<AntSelect.Option key={index} value={year}>
																	{year}
																</AntSelect.Option>
															))}
														</AntSelect>
													</AntForm.Item>

													<AntForm.Item
														label="Year to"
														name="year_to"
														initialValue={education[selectedEducationIndex]?.year_to}
														rules={[{required: true, message: 'Please select end year'}]}>
														<AntSelect placeholder="Select year">
															{years.map((year, index) => (
																<AntSelect.Option key={index} value={year}>
																	{year}
																</AntSelect.Option>
															))}
														</AntSelect>
													</AntForm.Item>

													<AntForm.Item
														label="Education description"
														name="description"
														initialValue={education[selectedEducationIndex]?.description}
														rules={[{required: true, message: 'Please enter education description'}]}>
														<AntInput.TextArea rows={6} placeholder="Education description" />
													</AntForm.Item>

													<div className="buttons">
														<Button icon={<CheckOutlined />} htmlType="submit">
															Save
														</Button>
														<Button
															icon={<CloseOutlined />}
															onClick={() => {
																setEdit3(false);
																setSelectedEducationIndex(null);
															}}>
															Cancel
														</Button>
													</div>
												</AntForm>
											</div>
										)}
									</div>
								)}
							</Spin>
						</div>
					</div>
				</div>
			</div>
			<NewPasswordPopup showPopup={popup} closePopup={closePopup} />

			{loading && <Loading />}
			<Footer backgroundColor={permColors.white} />
		</div>
	);
}
