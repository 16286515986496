import {mainUrl} from './Urls';
import {createJobCheckoutSession} from './stripeApi';
import api from './interceptor';
import {notification} from 'antd';

export const getJobs = async (currentPage, searchQuery = '', category = '', job_type = '', monthly_salary = '', engagement_type = '', country = '', datePosted = '') => {
	try {
		const params = {
			page: currentPage,
		};

		if (searchQuery) {
			params.search = searchQuery;
		}

		if (category) {
			params.category = category;
		}

		if (job_type) {
			params.job_type = job_type;
		}

		if (monthly_salary) {
			params.monthly_salary = monthly_salary;
		}

		if (engagement_type) {
			params.engagement_type = engagement_type;
		}

		if (country.length) {
			params.country = country.join(',');
		}

		if (datePosted) {
			params.date_posted = datePosted;
		}

		const {data} = await api.get(`${mainUrl}/auth/jobs/`, {
			params: params,
		});
		return data;
	} catch (error) {
		console.error(error);
		return [];
	}
};

export const getLikedJobs = async () => {
	try {
		const {data} = await api.get(mainUrl + '/auth/liked-jobs/', {
			headers: {
				Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
			},
		});
		return data;
	} catch (error) {
		console.error(error);
		return [];
	}
};

export async function getJobById(jobId) {
	try {
		const response = await api.get(`${mainUrl}/auth/jobs/${jobId}`, {
			headers: {
				Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
			},
		});
		console.log(response.data);

		return response.data;
	} catch (error) {
		console.error(error);
	}
}

export async function applyJobApi(jobId, file, userId, answers, coverLetterOrMessage) {
	const formData = new FormData();
	formData.append('job_id', jobId);
	formData.append('cover_letter_or_message', coverLetterOrMessage);
	if (file) {
		formData.append('attachment', file);
	}

	if (answers) {
		formData.append('answers', JSON.stringify(answers)); // Replace this section to stringify the answers
	}

	try {
		const response = await api.post(mainUrl + '/auth/jobs/job-offers/', formData, {
			headers: {
				Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
				'Content-Type': 'multipart/form-data',
			},
		});
		return true;
	} catch (error) {
		console.error('Error creating Job Offer:', error);
		return false;
	}
}

export async function getMyJobsApi() {
	try {
		const config = {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
			},
		};

		const response = await api.get(`${mainUrl}/auth/my-jobs/`, config);
		return response.data;
	} catch (error) {
		console.error(error);
	}
}

export async function getMyClosedJobsApi() {
	try {
		const config = {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
			},
		};

		const response = await api.get(`${mainUrl}/auth/my-completed-jobs/`, config);
		return response.data;
	} catch (error) {
		console.error(error);
	}
}

export async function getAppliedJobsApi() {
	try {
		const config = {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
			},
		};

		const response = await api.get(`${mainUrl}/auth/applied-jobs/`, config);
		return response.data;
	} catch (error) {
		console.error(error);
	}
}

export async function getJobOffersApi(jobId) {
	try {
		const config = {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
			},
		};
		const data = {
			job_id: jobId,
		};

		const response = await api.post(`${mainUrl}/auth/jobs/job-offers-by-job-id/`, data, config);
		return response.data;
	} catch (error) {
		console.error(error);
	}
}

export async function deleteJobApi(id) {
	try {
		const config = {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
			},
		};

		const response = await api.delete(`${mainUrl}/auth/user-jobs/${id}/`, config);
		console.log(response.data);
		return true;
	} catch (error) {
		console.error(error);
		return false;
	}
}
export async function editJobApi(
	id,
	jobTitle,
	description,
	companyName,
	country,
	jobType,
	category,
	engagementType,
	roleLocationType,
	roleLocation,
	paymentRateType,
	paymentRateFrom,
	paymentRateTo,
	paymentRateCurrency,
	showSalaryOnJob,
	workScheduleType,
	workScheduleTimezone,
	workScheduleDays,
	workScheduleHoursStart,
	workScheduleHoursEnd,
	workScheduleFlexibleNotes,
	shifts,
	equipmentAllocation,
	provideHMO,
	questions,
	coverLetterRequired,
	useExternalLink,
	externalLink
) {
	try {
		const config = {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
			},
		};

		console.log('api shifts', shifts);

		const jobData = {
			title: jobTitle,
			description: description,
			company_name: companyName,
			country: country,
			job_type: jobType,
			category: category,
			engagement_type: engagementType,
			role_location_type: roleLocationType,
			role_location: roleLocation,
			payment_rate_type: paymentRateType,
			payment_rate_from: paymentRateFrom,
			payment_rate_to: paymentRateTo,
			payment_rate_currency: paymentRateCurrency,
			show_salary_on_job: showSalaryOnJob,
			work_schedule_type: workScheduleType,
			work_schedule_timezone: workScheduleTimezone,
			work_schedule_days: workScheduleDays,
			work_schedule_hours_start: workScheduleHoursStart,
			work_schedule_hours_end: workScheduleHoursEnd,
			work_schedule_flexible_notes: workScheduleFlexibleNotes,
			shifts: shifts,
			equipment_allocation: equipmentAllocation,
			provide_HMO: provideHMO,
			questions: questions,
			provide_cover_letter: coverLetterRequired,
			use_external_link: useExternalLink,
			external_link: externalLink,
		};

		const response = await api.patch(`${mainUrl}/auth/user-jobs/${id}/`, jobData, config);

		console.log(response.data, true);
		return response.data;
	} catch (error) {
		console.error(error, false);
		return false;
	}
}

export async function postJobApi(
	jobTitle,
	description,
	companyName,
	country,
	jobType,
	category,
	engagementType,
	paymentRateType,
	paymentRateFrom,
	paymentRateTo,
	paymentRateCurrency,
	showSalaryOnJob,
	questions,
	coverLetterRequired,
	subscriptionType,
	roleLocationType,
	roleLocation,
	workScheduleType,
	workScheduleTimezone,
	workScheduleDays,
	workScheduleHoursStart,
	workScheduleHoursEnd,
	workScheduleFlexibleNotes,
	formattedShifts,
	equipmentAllocation,
	provideHMO,
	useExternalLink,
	externalLink
) {
	try {
		const config = {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
			},
		};

		let jobData = {
			title: jobTitle,
			description: description,
			company_name: companyName,
			country: country,
			job_type: jobType,
			category: category,
			engagement_type: engagementType,
			payment_rate_type: paymentRateType,
			payment_rate_from: paymentRateFrom,
			payment_rate_to: paymentRateTo,
			payment_rate_currency: paymentRateCurrency,
			show_salary_on_job: showSalaryOnJob,
			questions: questions,
			provide_cover_letter: coverLetterRequired,
			subscription_type: subscriptionType,
			role_location_type: roleLocationType,
			role_location: roleLocation,
			work_schedule_type: workScheduleType,
			work_schedule_timezone: workScheduleTimezone,
			work_schedule_days: workScheduleDays,
			work_schedule_hours_start: workScheduleHoursStart,
			work_schedule_hours_end: workScheduleHoursEnd,
			work_schedule_flexible_notes: workScheduleFlexibleNotes,
			shifts: formattedShifts,
			equipment_allocation: equipmentAllocation,
			provide_HMO: provideHMO,
			use_external_link: useExternalLink,
			external_link: externalLink,
		};

		const response = await api.post(`${mainUrl}/auth/new-job/`, jobData, config);
		notification.success({
			message: 'Job posted successfully',
			description: 'Your job has been posted successfully',
			placement: 'topRight',
			duration: 5,
		});
		return response.data;
	} catch (error) {
		console.error(error);
		if (error.response && error.response.data) {
			notification.error({
				message: 'Error',
				description: error.response.data.detail || 'An error occurred while posting the job',
				placement: 'topRight',
				duration: 5,
			});
		} else {
			notification.error({
				message: 'Error',
				description: 'An error occurred while posting the job',
				placement: 'topRight',
				duration: 5,
			});
		}
		throw error;
	}
}

export async function patchJobStripe(id, stripeSubscription) {
	try {
		const config = {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
			},
		};

		const response = await api.patch(`${mainUrl}/auth/user-jobs/${id}/`, {
			stripe_subscription: stripeSubscription,
		});
		return response.data;
	} catch (error) {
		console.error(error);
	}
}

export async function acceptOrDeclineJobOfferApi(jobOfferId, accepted, declined) {
	try {
		const url = `${mainUrl}/auth/jobs/accept-decline-job-offer/`;
		const headers = {Authorization: `Bearer ${localStorage.getItem('accessToken')}`};
		const data = {job_offer_id: jobOfferId, accepted, declined};

		const response = await api.patch(url, data, {headers});
		console.log(response.data);
		return response.data;
	} catch (error) {
		console.error(error);
	}
}

export async function markJobOfferAsSeenApi(jobOfferId) {
	try {
		const url = `${mainUrl}/auth/jobs/job-offer-seen/`;
		const headers = {Authorization: `Bearer ${localStorage.getItem('accessToken')}`};
		const data = {job_offer_id: jobOfferId};

		const response = await api.patch(url, data, {headers});
		console.log(response.data);
		return response.data;
	} catch (error) {
		console.error(error);
	}
}

export async function updateJobOfferApi(jobOfferId, candidateFit) {
	try {
		const url = `${mainUrl}/auth/jobs/job-offers-update/`;
		const headers = {Authorization: `Bearer ${localStorage.getItem('accessToken')}`};
		const data = {job_offer_id: jobOfferId, candidate_fit: candidateFit};

		const response = await api.patch(url, data, {headers});
		console.log(response.data);
		return response.data;
	} catch (error) {
		console.error(error);
		return false;
	}
}

export async function likeJobApi(id) {
	try {
		const response = await api.patch(mainUrl + `/auth/like-job/${id}/`, null, {
			headers: {
				Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
			},
		});
		console.log(response.data);
	} catch (error) {
		console.error('Error liking the job:', error);
		return 'error';
	}
}

export async function updateUniqueExternalLinkClicksApi(jobId) {
	try {
		const response = await api.post(`${mainUrl}/auth/jobs/update-unique-external-link-clicks/${jobId}/`);
		console.log(response.data);
		return response.data;
	} catch (error) {
		console.error(error);
	}
}
