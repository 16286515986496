import React, {useEffect, useState, useContext} from 'react';
import {useParams} from 'react-router-dom';
import {Card, Typography, Divider, Tag, Space, Collapse, Alert} from 'antd';
import {Helmet} from 'react-helmet';
import {capitalize} from '@material-ui/core';
import Navbar from '../../components/Navbars/Navbar2';
import Footer from '../../components/Footer/Footer';
import {UserContext} from '../../utils/UserContext';
import './JobPage.scss';
import Loading from '../../components/Loaders/Loading';
import {getJobById} from '../../api/jobsApi';
import {textFormatter} from '../../utils/TextAlterations';
import ApplyJobButton from '../../components/ApplyJob/ApplyJobButton';
import {permColors, tailwindPermColors} from '../../utils/colors';
import {WorkOutlined, AccessTime, LocationOn, CalendarToday, Computer, HealthAndSafety, Schedule, Work} from '@mui/icons-material';
import {daysAgo} from '../../utils/DateFunctions';
import {formatCurrency} from '../../utils/currency';
import {ROUTES} from '../../utils/ROUTES';
const {Text, Paragraph} = Typography;
const {Panel} = Collapse;

export default function JobPage() {
	const [job, setJob] = useState({});
	const {jobId} = useParams();
	const [currentUser, setCurrentUser] = useContext(UserContext);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		async function fetchJob() {
			const jobData = await getJobById(jobId);
			setJob(jobData);
			setLoading(false);
		}
		fetchJob();
	}, [currentUser]);

	if (loading) {
		return <Loading />;
	}

	return (
		<div style={{backgroundColor: permColors.backgroundGreyBlue}}>
			<Helmet>
				<title>{`${job?.title ? job?.title : 'Job'} | Permworks`}</title>
				<meta name="description" content={`Apply for ${job?.title ? job?.title : 'Job'} at Permworks. Join our global talent network.`} />
				<meta name="canonical" content={`https://permworks.com/${ROUTES.JOB}/${job?.id}`} />
			</Helmet>
			<Navbar />
			<div className="py-8 px-4 sm:px-6 lg:px-8">
				<Card className="max-w-4xl mx-auto">
					<div className="flex flex-col gap-1">
						<div className="flex items-center justify-between gap-2 mb-4">
							<div className="rounded-full border border-gray-200 p-2 w-fit">
								{job?.created_by_image ? <img src={job?.created_by_image} alt="profile" className="working-icon p-0" /> : <WorkOutlined className="working-icon" />}
							</div>
							<div className="flex items-center gap-2 flex-wrap">
								<ApplyJobButton job={job} />
							</div>
						</div>

						<p className="text-base font-semibold flex items-center gap-2" style={{color: permColors.charcoal}}>
							{job?.company_name && capitalize(job?.company_name)}
							<span className="text-base font-light opacity-80" style={{color: permColors.grey}}>
								{job?.created_at !== null ? `Posted on the ${daysAgo(job?.created_at)} day${daysAgo(job?.created_at) === 1 ? '' : 's'} ago` : ''}
							</span>
						</p>

						<span className="text-2xl font-medium mb-2" style={{color: permColors.darkCharcoal}}>
							{job?.title && capitalize(job?.title)}
						</span>

						<div className="flex items-center flex-wrap gap-2">
							<Tag className="text-sm m-0">{job.engagement_type}</Tag>
							<Tag className="text-sm m-0">{job.category}</Tag>
							<Tag className="text-sm m-0">{job.job_type}</Tag>
						</div>
					</div>

					<div className="flex flex-col gap-1 mt-2">
						{job?.payment_rate_from && job?.show_salary_on_job && (
							<p className="text-base font-semibold" style={{color: permColors.darkCharcoal}}>
								<span>{formatCurrency(job.payment_rate_from, job.payment_rate_to, job.payment_rate_type, job.payment_rate_currency)}</span>
							</p>
						)}

						{(job?.monthly_salary || job?.hourly_rate) && (
							<p className="text-base font-semibold" style={{color: permColors.darkCharcoal}}>
								{job?.monthly_salary && (
									<span>
										${parseFloat(job.monthly_salary).toString()}
										{job?.max_monthly_salary !== null && <span> - ${parseFloat(job.max_monthly_salary).toString()}</span>} /month (USD)
									</span>
								)}
								{job?.hourly_rate && (
									<span>
										${parseFloat(job.hourly_rate).toString()}
										{job?.max_hourly_rate !== null && <span> - ${parseFloat(job.max_hourly_rate).toString()}</span>} /hr (USD)
									</span>
								)}
							</p>
						)}

						<div className="flex flex-col gap-2 mt-2">
							<div className="flex items-center gap-2">
								<LocationOn style={{fontSize: '18px', color: permColors.charcoal, opacity: 0.3}} />
								<span className="text-sm" style={{color: permColors.charcoal}}>
									<span className="font-semibold">Company location:</span> {job.country}
								</span>
							</div>

							{job?.work_schedule_timezone && (job?.work_schedule_type === 'fixed' || job?.work_schedule_type === 'shift-based') && (
								<div className="flex items-center gap-2">
									<AccessTime style={{fontSize: '18px', color: permColors.charcoal, opacity: 0.3}} />
									<span className="text-sm" style={{color: permColors.charcoal}}>
										<span className="font-semibold">Timezone:</span> {job.work_schedule_timezone}
									</span>
								</div>
							)}
							{job?.work_schedule_type && (
								<div className="flex items-center gap-2">
									<Work style={{fontSize: '18px', color: permColors.charcoal, opacity: 0.3}} />
									<span className="text-sm" style={{color: permColors.charcoal}}>
										<span className="font-semibold">Working schedule type:</span> <span className="capitalize">{job.work_schedule_type}</span>
									</span>
								</div>
							)}
							{job?.work_schedule_type === 'fixed' && job?.work_schedule_hours_start && (
								<div className="flex items-center gap-2">
									<AccessTime style={{fontSize: '18px', color: permColors.charcoal, opacity: 0.3}} />
									<span className="text-sm" style={{color: permColors.charcoal}}>
										<span className="font-semibold">Working hours:</span> {job.work_schedule_hours_start} - {job.work_schedule_hours_end}
									</span>
								</div>
							)}

							{job?.work_schedule_days && job?.work_schedule_type === 'fixed' && (
								<div className="flex items-center gap-2">
									<CalendarToday style={{fontSize: '18px', color: permColors.charcoal, opacity: 0.3}} />
									<p className="text-sm" style={{color: permColors.charcoal}}>
										<span className="font-semibold">Working days:</span>{' '}
										{job.work_schedule_days
											.split(',')
											.map((day) => capitalize(day))
											.join(', ')}
									</p>
								</div>
							)}

							{job?.shifts?.length > 0 && (
								<div className="flex flex-col gap-2">
									<div className="flex items-center gap-2">
										<AccessTime style={{fontSize: '18px', color: permColors.charcoal, opacity: 0.3}} />
										<span className="text-sm font-semibold" style={{color: permColors.charcoal}}>
											Shifts:
										</span>
									</div>
									<div className="flex flex-col gap-1 ml-6">
										{job.shifts.map((shift, index) => (
											<p key={index} className="text-sm" style={{color: permColors.charcoal}}>
												{shift.day_of_week}: {shift.start_time} - {shift.end_time}
											</p>
										))}
									</div>
								</div>
							)}

							{job?.equipment_allocation && (
								<p className="text-sm" style={{color: permColors.charcoal}}>
									<div className="flex items-center gap-2">
										<Computer style={{fontSize: '18px', color: permColors.charcoal, opacity: 0.3}} />
										<span className="font-semibold">Equipment:</span> <span className="capitalize">{job.equipment_allocation}</span>
									</div>
								</p>
							)}
							{job.provide_HMO && (
								<p className="text-sm" style={{color: permColors.charcoal}}>
									<div className="flex items-center gap-2">
										<HealthAndSafety style={{fontSize: '18px', color: permColors.charcoal, opacity: 0.3}} />
										<span className="font-semibold">Benefits:</span> HMO Provided
									</div>
								</p>
							)}
						</div>
					</div>

					<Divider />
					<p className="text-base font-semibold underline underline-offset-2 mb-2" style={{color: permColors.darkCharcoal}}>
						Job Description
					</p>

					{job?.description && (
						<div
							className={`grow text-charcoal text-base [&>p]:text-base`}
							dangerouslySetInnerHTML={{
								__html: job?.description,
							}}
						/>
					)}

					<Divider />

					{job?.questions?.length > 0 ? (
						<Collapse>
							<Panel header={job?.questions.length > 1 ? 'Application Questions' : 'Application question'} key="1">
								<Space direction="vertical" size="small">
									{job.questions?.map((q, index) => (
										<Text key={index}>
											{index + 1}. {capitalize(q?.question)}
										</Text>
									))}
								</Space>
							</Panel>
						</Collapse>
					) : (
						<Alert message="No required questions to answer for this job" className="rounded-md" type="info" showIcon banner />
					)}
				</Card>
			</div>
			<Footer backgroundColor={permColors.backgroundLightCream} />
		</div>
	);
}
