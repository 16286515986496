import React, {useState} from 'react';
import {CardElement, useStripe, useElements} from '@stripe/react-stripe-js';
import {stripeAddPaymentMethod} from '../../api/stripeApi';
import {Alert, LinearProgress} from '@mui/material';
import {permColors} from '../../utils/colors';
import {Button, message} from 'antd';
import {CreditCard} from '@mui/icons-material';

export default function AddPaymentMethodForm(props) {
	const stripe = useStripe();
	const elements = useElements();
	const [loading, setLoading] = useState(false);
	const [errorMessages, setErrorMessages] = useState('');
	const [successMessage, setSuccessMessage] = useState('');

	const handleSubmit = async () => {
		if (!stripe || !elements) {
			return;
		}

		setErrorMessages('');
		setLoading(true);
		const cardElement = elements.getElement(CardElement);
		const {error, paymentMethod} = await stripe.createPaymentMethod({
			type: 'card',
			card: cardElement,
		});

		if (error) {
			console.error('[stripe error]', error);
			setErrorMessages(error.message);
			setLoading(false);
		} else {
			const response = await stripeAddPaymentMethod(paymentMethod.id);
			if (response.error) {
				setErrorMessages(response.message);
			} else {
				setLoading(false);
				props.setRetriggerGetPaymentMethod(!props.retriggerGetPaymentMethod);
				message.success('Payment method added successfully', 5);
			}
			setLoading(false);
		}
	};

	const CARD_ELEMENT_OPTIONS = {
		style: {
			base: {
				color: permColors.darkCharcoal,
				'::placeholder': {
					color: '#aab7c4',
				},
				fontFamily: 'Plus Jakarta Sans, sans-serif',
				lineHeight: '28px',
				fontSize: '16px',
			},

			invalid: {
				color: permColors.red,
				iconColor: permColors.red,
			},
		},
	};

	return (
		<div className="addPaymentMethodForm">
			<div>
				<h4 style={{color: permColors.darkCharcoal}} className="text-lg mb-2">
					{props.cardDetails ? 'Update Payment Method' : 'Add Payment Method'}
				</h4>
				<div className="card-element-container mb-2 max-w-[500px]">
					<CardElement options={CARD_ELEMENT_OPTIONS} />
				</div>
				{loading && <LinearProgress className="my-2 max-w-[500px]" />}
				{errorMessages && (
					<Alert severity="error" className="my-2 max-w-[500px] rounded-md">
						{errorMessages}
					</Alert>
				)}
				{successMessage && (
					<Alert severity="success" className="my-2 max-w-[500px] rounded-md">
						{successMessage}
					</Alert>
				)}
				<Button onClick={handleSubmit} type="primary" disabled={!stripe || loading} icon={<CreditCard className="text-base" />} className="min-h-[40px] rounded-md">
					{props.cardDetails ? 'Update payment method' : 'Add payment method'}
				</Button>
			</div>
		</div>
	);
}
