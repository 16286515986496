import {atom} from 'jotai';

export type menuItems =
	| 'inbox'
	| 'jobs'
	| 'closedJobs'
	| 'payments'
	| 'subscriptions'
	| 'billingHistory'
	| 'notifications'
	| 'appliedJobs'
	| 'savedJobs'
	| 'onboarding'
	| 'team'
	| 'timeOff'
	| 'payroll';

function getStoredSelected() {
	return sessionStorage.getItem('selected' as menuItems) || ('inbox' as menuItems);
}

export const teamsAtom = atom<any[]>([]);
export const selectedAtom = atom<menuItems>(getStoredSelected() as menuItems);
export const conversationsAtom = atom<any[]>([]);
export const appliedJobRetriggerAtom = atom<boolean>(false);

export const jobsAtom = atom<any[]>([]);
export const jobsAtomTriggered = atom<boolean>(false);

export const likedJobsAtom = atom<any[]>([]);
export const likedJobsAtomTriggered = atom<boolean>(false);

export const selectedJobAtom = atom<any>({});
export const selectedJobAtomTriggered = atom<boolean>(false);

export const totalJobsAtom = atom<number>(0);
export const totalPagesAtom = atom<number>(1);
export const currentJobsPageAtom = atom<number>(1);

export const triggerFetchingUserAtom = atom<boolean>(false);
export const triggerFetchingJobsAfterCloseAtom = atom<boolean>(false);

export const unreadNotificationsAtom = atom<number>(0);

export const isSignupAtom = atom<boolean>(false);
