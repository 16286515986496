import React, {useEffect, useState, useContext} from 'react';
import './DashboardComponent.scss';

import {loadStripe} from '@stripe/stripe-js';
import {Elements} from '@stripe/react-stripe-js';
import {UserContext} from '../../utils/UserContext';
import AddPaymentMethodForm from '../Stripe/AddPaymentMethodForm';
import {getCurrentUserPaymentMethodApi} from '../../api/stripeApi';
import LoadingPartial from '../Loaders/LoadingPartial';
import QueueAnim from 'rc-queue-anim';
import {cardDetailsAtom} from '../../utils/atoms/dashboardAtoms';
import {useAtom} from 'jotai';
import {CreditCard} from '@mui/icons-material';
import {Card} from 'antd';
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK_TEST_KEY); // Replace this with your Stripe publishable key

export default function Payments({setPaymentConfirmed, headerVisible = true, noPadding = false}) {
	const [currentUser] = useContext(UserContext);
	const [cardDetails, setCardDetails] = useAtom(cardDetailsAtom);
	const [loading, setLoading] = useState(false);
	const [retriggerGetPaymentMethod, setRetriggerGetPaymentMethod] = useState(false);
	async function getPaymentMethod() {
		setLoading(true);
		const res = await getCurrentUserPaymentMethodApi();
		setCardDetails(res);
		setLoading(false);
		if (setPaymentConfirmed) {
			if (res === false || res === null) {
				setPaymentConfirmed(false);
			}
			if (res !== false && res !== null) {
				setPaymentConfirmed(true);
			}
		}
	}
	useEffect(() => {
		getPaymentMethod();
	}, [retriggerGetPaymentMethod]);

	if (loading) {
		return <LoadingPartial />;
	}

	return (
		<div className="dashboardComponent">
			{headerVisible && (
				<Card classNames={{body: 'p-2'}} className="mb-2">
					<div className="flex justify-between items-center gap-2 flex-wrap">
						<h2 className="flex items-center gap-2 px-2 text-lg font-semibold text-darkCharcoal">
							<CreditCard className="text-base" /> Payment Method
						</h2>
					</div>
				</Card>
			)}

			<Card className={noPadding ? 'mx-1 mt-1 mb-3' : ''}>
				<div className="flex flex-col gap-4">
					<QueueAnim delay={100} interval={200}>
						{cardDetails ? (
							<div className="bg-white rounded-lg shadow-border p-6  max-w-[400px] mb-4">
								<div className="flex justify-between items-center mb-4">
									<span className="text-lg font-medium">Stripe Payment</span>
								</div>

								<div className="space-y-4">
									<div className="w-12 h-8 bg-deepBlue rounded"></div>
									<div className="text-xl font-mono">**** **** **** {cardDetails.last4}</div>
									<div className="grid grid-cols-2 gap-4">
										<div>
											<label className="block text-sm text-grey">Cardholder</label>
											<span className="font-medium text-darkCharcoal">
												{currentUser?.profile?.first_name} {currentUser?.profile?.last_name}
											</span>
										</div>
										<div>
											<label className="block text-sm text-grey">Expiry</label>
											<span className="font-medium text-darkCharcoal">
												{cardDetails.exp_month}/{cardDetails.exp_year}
											</span>
										</div>
									</div>
								</div>
							</div>
						) : (
							<div className="text-center p-4">No card information available.</div>
						)}
						<Elements stripe={stripePromise}>
							<AddPaymentMethodForm cardDetails={cardDetails} retriggerGetPaymentMethod={retriggerGetPaymentMethod} setRetriggerGetPaymentMethod={setRetriggerGetPaymentMethod} />
						</Elements>
					</QueueAnim>
				</div>
			</Card>
		</div>
	);
}
