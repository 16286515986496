import React, {useEffect, useState} from 'react';
import {getLikedJobs, likeJobApi} from '../../../api/jobsApi';
import {Link} from 'react-router-dom';
import {capitalize} from '@mui/material';
import {textFormatter} from '../../../utils/TextAlterations';
import LoadingPartial from '../../Loaders/LoadingPartial';
import {filterByJobType, filterBySearchTerm} from '../../../utils/filters';
import {Button, Input, message, Select, Table, Card} from 'antd';
import {WorkOutline} from '@mui/icons-material';
import {SearchOutlined} from '@ant-design/icons';

const SavedJobs = () => {
	const [jobs, setJobs] = useState([]);
	const [loading, setLoading] = useState(true);
	const [searchTerm, setSearchTerm] = useState('');
	const [jobTypeFilter, setJobTypeFilter] = useState('all');

	async function getLiked() {
		const res = await getLikedJobs();
		setJobs(res);
		setLoading(false);
	}

	useEffect(() => {
		getLiked();
	}, []);

	async function unlike(id) {
		const res = await likeJobApi(id);
		if (res === 'error') {
			message.error('Error unsaving the job');
		} else {
			getLiked();
			message.success('Job removed from saved');
		}
	}

	const columns = [
		{
			title: 'Title',
			dataIndex: 'title',
			key: 'title',
			render: (text, record) => <Link to={`/jobpage/${record.id}`}>{capitalize(text)}</Link>,
		},
		{
			title: 'Company',
			dataIndex: 'company_name',
			key: 'company_name',
			render: (text) => capitalize(text),
		},
		{
			title: 'Type',
			dataIndex: 'job_type',
			key: 'job_type',
		},
		{
			title: 'Engagement',
			dataIndex: 'engagement_type',
			key: 'engagement_type',
		},
		{
			title: 'Description',
			dataIndex: 'description',
			key: 'description',
			render: (text) => <div style={{maxHeight: '120px', overflowY: 'auto'}} dangerouslySetInnerHTML={{__html: textFormatter(text)}} />,
		},
		{
			title: 'Action',
			key: 'action',
			render: (_, record) => (
				<Button type="default" onClick={() => unlike(record.id)}>
					Unsave
				</Button>
			),
		},
	];

	if (loading) {
		return <LoadingPartial />;
	}

	const filteredJobs = jobs.filter(filterBySearchTerm(searchTerm)).filter(filterByJobType(jobTypeFilter));

	return (
		<div className="dashboardComponent">
			<Card classNames={{body: 'p-2'}} className="mb-2">
				<div className="flex justify-between items-center gap-2 flex-wrap">
					<h2 className="flex items-center gap-2 px-2 text-lg font-semibold text-darkCharcoal">
						<WorkOutline className="text-base" /> Saved Jobs
					</h2>
					<div className="flex flex-wrap gap-2">
						<Input
							prefix={<SearchOutlined />}
							placeholder="Search by title or company name"
							onChange={(e) => setSearchTerm(e.target.value.toLowerCase())}
							className="p-2 w-[250px] mobile-width-100"
						/>
						<Select
							defaultValue="all"
							className="grow sm:w-[250px]"
							options={[
								{label: 'All Job Types', value: 'all'},
								{label: 'Full Time', value: 'full-time'},
								{label: 'Part Time', value: 'part-time'},
							]}
							onChange={(value) => setJobTypeFilter(value)}
						/>
					</div>
				</div>
			</Card>
			<Table bordered dataSource={filteredJobs} columns={columns} rowKey="id" scroll={{x: true}} />
		</div>
	);
};

export default SavedJobs;
