import React, {useState} from 'react';
import {Modal, Form, Input, Button, message, Alert, Typography} from 'antd';
import {setNewPasswordApi} from '../../api/profileApi';
import {permColors} from '../../utils/colors';
import {LockOutlined} from '@ant-design/icons';

const {Text} = Typography;

export default function NewPasswordPopup({showPopup, closePopup}) {
	const [form] = Form.useForm();
	const [loading, setLoading] = useState(false);

	const resetPassword = async (values) => {
		setLoading(true);
		try {
			const res = await setNewPasswordApi(values.oldPassword, values.newPassword);
			message.success('Password changed successfully');
			form.resetFields();
			closePopup();
		} catch (error) {
			if (error?.response?.data?.new_password || error?.response?.data?.old_password) {
				error?.response?.data?.new_password && message.error(error?.response?.data?.new_password);
				error?.response?.data?.old_password && message.error(error?.response?.data?.old_password);
			} else {
				message.error('Failed to change password. Please try again.');
			}
			console.log(error);
		} finally {
			setLoading(false);
		}
	};

	return (
		<Modal
			visible={showPopup}
			onCancel={closePopup}
			title={
				<Text strong style={{fontSize: '1.2em', color: permColors.darkCharcoal}}>
					Change Password
				</Text>
			}
			footer={null}
			style={{color: permColors.darkCharcoal}}>
			<Alert
				message="Recommended Password Strength"
				description={
					<ul className="list-disc pl-4 text-sm">
						<li>
							Use at least 8 characters <strong>(required)</strong>
						</li>
						<li>Include uppercase letters (A-Z)</li>
						<li>Include lowercase letters (a-z)</li>
						<li>Include numbers (0-9)</li>
						<li>Include special characters (!@#$%^&*)</li>
					</ul>
				}
				type="info"
				showIcon
				className="mb-4 rounded-md"
				banner
			/>

			<Form form={form} onFinish={resetPassword} layout="vertical" requiredMark="optional">
				<Form.Item name="oldPassword" label={<Text strong>Current Password</Text>} rules={[{required: true, message: 'Please input your current password!'}]}>
					<Input.Password prefix={<LockOutlined className="site-form-item-icon" />} placeholder="Enter your current password" />
				</Form.Item>

				<Form.Item name="newPassword" label={<Text strong>New Password</Text>} rules={[{required: true, message: 'Please input your new password!'}]}>
					<Input.Password prefix={<LockOutlined className="site-form-item-icon" />} placeholder="Enter your new password" />
				</Form.Item>

				<Form.Item
					name="confirmPassword"
					label={<Text strong>Confirm New Password</Text>}
					dependencies={['newPassword']}
					rules={[
						{required: true, message: 'Please confirm your new password!'},
						({getFieldValue}) => ({
							validator(_, value) {
								if (!value || getFieldValue('newPassword') === value) {
									return Promise.resolve();
								}
								return Promise.reject(new Error('The two passwords do not match!'));
							},
						}),
					]}>
					<Input.Password prefix={<LockOutlined className="site-form-item-icon" />} placeholder="Confirm your new password" />
				</Form.Item>

				<Form.Item className="mb-0 flex justify-end">
					<Button onClick={closePopup} style={{marginRight: '10px'}}>
						Cancel
					</Button>
					<Button type="primary" htmlType="submit" loading={loading}>
						Change Password
					</Button>
				</Form.Item>
			</Form>
		</Modal>
	);
}
