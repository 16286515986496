import React, {useContext, useEffect, useState} from 'react';
import './inbox.scss';
import LoadingPartial from '../Loaders/LoadingPartial';
import {UserContext} from '../../utils/UserContext';
import {AiOutlineSend} from 'react-icons/ai';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Card from 'react-bootstrap/Card';
import {ArrowBack} from '@material-ui/icons';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Slide from '@mui/material/Slide';
import Hidden from '@mui/material/Hidden';
import Placeholder from 'react-bootstrap/Placeholder';
import {permColors} from '../../utils/colors';
import {Input, Spin} from 'antd';
import TextArea from 'antd/es/input/TextArea';
import ChatBox from './ChatBox';
import {Card as AntdCard} from 'antd';
import {InboxOutlined} from '@ant-design/icons';
import {useInbox} from './useInbox';
const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const MessageInput = ({message, setMessage, sendMessage, sendMessageLoading}) => (
	<div className="textarea-container">
		<TextArea value={message} onChange={(e) => setMessage(e.target.value)} rows={4} className="message-textarea" autoSize disabled={sendMessageLoading} />
		{sendMessageLoading ? <Spin size="small" className="send-icon bottom-2 cursor-wait mb-1 mr-1" /> : <AiOutlineSend className="send-icon font-icon" onClick={sendMessage} />}
	</div>
);

export default function Inbox() {
	const [currentUser] = useContext(UserContext);
	const {
		displayedConversations,
		messagesContent,
		loading,
		selectedConversation,
		message,
		selectedUser,
		open,
		sendMessageLoading,
		fetchingMessages,
		handleClose,
		sendMessage,
		fetchMessages,
		runFilter,
		setMessage,
	} = useInbox(currentUser);

	if (loading) return <LoadingPartial />;

	return (
		<div className="dashboardComponent">
			<AntdCard classNames={{body: 'p-2'}} className="mb-2">
				<div className="flex justify-between items-center gap-2">
					<h2 className="flex items-center gap-2 px-2 text-lg font-semibold text-darkCharcoal">
						<InboxOutlined className="text-base" /> Inbox
					</h2>
				</div>
			</AntdCard>
			<AntdCard classNames={{body: 'p-0'}}>
				<div className="h-[calc(100dvh-120px)] overflow-y-auto flex flex-col">
					<div className="flex h-full">
						<Hidden mdUp>
							<DialogPopup
								open={open}
								handleClose={handleClose}
								selectedUser={selectedUser}
								messagesContent={messagesContent}
								currentUser={currentUser}
								setMessage={setMessage}
								message={message}
								sendMessage={sendMessage}
								sendMessageLoading={sendMessageLoading}
							/>
						</Hidden>
						<div className="flex w-full">
							<div className="w-full md:w-1/3 border-r border-gray-200">
								<div className="h-full flex flex-col bg-white rounded-l-lg shadow-sm">
									<div className="p-4 border-b border-gray-200">
										<h3 className="text-lg font-medium text-gray-900">Messages</h3>
									</div>

									<div className="p-4">
										<Input type="text" placeholder="Filter Names" onChange={runFilter} className="w-full mb-4" />
										<p className="text-sm text-gray-600 mb-2">
											{displayedConversations?.length} conversation{displayedConversations?.length !== 1 && 's'}
										</p>
									</div>

									<div className="flex-1 overflow-y-auto px-4">
										{displayedConversations?.length > 0 ? (
											displayedConversations.map((conversation) => (
												<div key={conversation.id} className="transition-all duration-200">
													{conversation.users
														.filter((user) => user?.id !== currentUser.id)
														.map((user, index) => (
															<div
																key={index}
																className={`p-3 mb-2 rounded-lg transition-all duration-200 cursor-pointer
																	${selectedConversation === conversation.id ? 'bg-backgroundGreyBlue' : 'hover:bg-backgroundGrey'}`}
																onClick={() => fetchMessages(conversation.id, user)}>
																<div className="flex items-start">
																	<ListItemAvatar>
																		<AvatarWithLoading
																			alt={`${user?.profile?.first_name ?? ''} ${user?.profile?.last_name ?? 'Applicant'}`}
																			src={user?.profile?.profile_image ?? '/static/images/avatar/default.jpg'}
																		/>
																	</ListItemAvatar>
																	<div>
																		<p className="font-medium text-gray-900">{`${user?.profile?.first_name ?? 'Unknown'} ${user?.profile?.last_name ?? 'User'}`}</p>
																		{user?.profile?.company_name && user?.profile?.company_name !== 'undefined' && (
																			<p className="text-sm text-grrey">{user?.profile?.company_name}</p>
																		)}
																	</div>
																</div>
															</div>
														))}
												</div>
											))
										) : (
											<p className="text-sm text-gray-500">No conversations yet.</p>
										)}
									</div>
								</div>
							</div>

							<Hidden mdDown>
								<div className="w-2/3">
									<div className="h-full flex flex-col bg-white rounded-r-lg shadow-sm">
										<div className="p-4 border-b border-gray-200">
											<div className="flex items-center">
												<ListItemAvatar>
													<AvatarWithLoading
														alt={`${selectedUser?.profile?.first_name ?? ''} ${selectedUser?.profile?.last_name ?? 'Applicant'}`}
														src={selectedUser?.profile?.profile_image ?? '/static/images/avatar/default.jpg'}
													/>
												</ListItemAvatar>
												<div className="ml-3">
													<p className="text-sm font-medium text-gray-900">
														{selectedConversation && selectedUser
															? `${selectedUser?.profile?.first_name ?? 'Unknown'} ${selectedUser?.profile?.last_name ?? 'User'}`
															: 'Select a conversation'}
													</p>
													<p className="text-sm text-gray-500">
														{selectedConversation && selectedUser ? (selectedUser?.profile?.company_name !== 'undefined' ? selectedUser?.profile?.company_name : '') : ''}
													</p>
												</div>
											</div>
										</div>

										{selectedConversation && selectedUser ? (
											<>
												<div className="flex-1 overflow-y-auto p-4 ">
													<ChatBox messagesContent={messagesContent} currentUser={currentUser} />
												</div>
												<div className="p-4 border-t border-gray-200">
													<MessageInput message={message} setMessage={setMessage} sendMessage={sendMessage} sendMessageLoading={sendMessageLoading} />
												</div>
											</>
										) : (
											<>
												{!fetchingMessages ? (
													<div className="flex-1 p-4 flex items-center justify-center">
														<div className="text-center">
															<p className="text-sm text-gray-500 mb-4">Select a conversation to view messages</p>
															<Placeholder xs={6} />
															<Placeholder className="w-75" />
															<Placeholder style={{width: '25%'}} />
														</div>
													</div>
												) : (
													<div className="flex-1 flex items-center justify-center">
														<Spin size="large" />
													</div>
												)}
											</>
										)}
									</div>
								</div>
							</Hidden>
						</div>
					</div>
				</div>
			</AntdCard>
		</div>
	);
}

export function AvatarWithLoading({src, ...otherProps}) {
	const [loadedSrc, setLoadedSrc] = useState('/static/images/avatar/default.jpg');

	useEffect(() => {
		const image = new Image();
		image.src = src;
		image.onload = () => {
			setLoadedSrc(src);
		};
	}, [src]);

	return <Avatar src={loadedSrc} {...otherProps} sx={{border: '1px solid ' + permColors.backgroundGrey}} />;
}

const DialogPopup = ({handleClose, open, selectedUser, messagesContent, currentUser, setMessage, message, sendMessage, sendMessageLoading}) => (
	<Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
		<AppBar sx={{position: 'sticky', backgroundColor: permColors.white}} className="shadow-sm">
			<Toolbar>
				<IconButton edge="start" color="inherit" onClick={handleClose} aria-label="back">
					<ArrowBack style={{color: permColors.darkCharcoal}} />
				</IconButton>
				<div className="flex items-center pl-3">
					<ListItemAvatar>
						<AvatarWithLoading
							alt={`${selectedUser?.profile?.first_name ?? ''} ${selectedUser?.profile?.last_name ?? 'Applicant'}`}
							src={selectedUser?.profile?.profile_image ?? '/static/images/avatar/default.jpg'}
						/>
					</ListItemAvatar>
					<div className="flex row text-left" style={{color: permColors.charcoal}}>
						<span className="text-sm pl-2">
							{selectedUser ? `${selectedUser?.profile?.first_name ?? 'Unknown'} ${selectedUser?.profile?.last_name ?? 'User'}` : 'Select a conversation'}
						</span>
						<span className="text-sm font-normal pl-2">{selectedUser ? (selectedUser?.profile?.company_name !== 'undefined' ? selectedUser?.profile?.company_name : '') : ''}</span>
					</div>
				</div>
			</Toolbar>
		</AppBar>
		<Card.Body style={{backgroundColor: permColors.white}}>
			<ChatBox messagesContent={messagesContent} currentUser={currentUser} />
		</Card.Body>
		<Card.Footer className="d-flex align-items-center justify-content-between m-0 p-2" style={{position: 'sticky', bottom: '0', backgroundColor: '#fffffe'}}>
			<MessageInput message={message} setMessage={setMessage} sendMessage={sendMessage} sendMessageLoading={sendMessageLoading} />
		</Card.Footer>
	</Dialog>
);
