import React, {useState} from 'react';
import {Card, Input, Button, message, Form, Layout, Menu} from 'antd';
import axios from 'axios';
import {mainUrl} from '../../api/Urls';
import Navbar from '../../components/Navbars/Navbar2';
import {UserSwitchOutlined, FileTextOutlined, MenuFoldOutlined, MenuUnfoldOutlined} from '@ant-design/icons';
import {useMediaQuery} from '@material-ui/core';

const {Content, Sider} = Layout;

export default function AdminPage() {
	const [loading, setLoading] = useState(false);
	const [form] = Form.useForm();
	const [selectedKey, setSelectedKey] = useState('ghost');
	const [collapsed, setCollapsed] = useState(false);
	const isMobile = useMediaQuery('(max-width: 768px)');

	const handleGhost = async (values) => {
		try {
			setLoading(true);
			const response = await axios.post(
				`${mainUrl}/auth/ghost/`,
				{
					email: values.email,
				},
				{
					headers: {
						'Content-Type': 'application/json',
						Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
					},
				}
			);

			// Update tokens
			localStorage.setItem('accessToken', response.data.tokens.access);
			localStorage.setItem('token', response.data.tokens.refresh);

			window.location.href = '/';
			message.success('Successfully ghosting user');
		} catch (error) {
			message.error(error.response?.data?.error || 'Failed to ghost user');
		} finally {
			setLoading(false);
		}
	};

	const items = [
		{
			key: 'ghost',
			icon: <UserSwitchOutlined />,
			label: 'Ghost Mode',
		},
		{
			key: 'logs',
			icon: <FileTextOutlined />,
			label: 'System Logs',
		},
	];

	const renderContent = () => {
		switch (selectedKey) {
			case 'ghost':
				return (
					<Card title="Admin Ghost Mode" className="max-w-[500px] w-full">
						<Form form={form} onFinish={handleGhost} layout="vertical">
							<Form.Item name="email" label="User Email to Ghost" rules={[{required: true, message: 'Please enter an email'}]}>
								<Input placeholder="Enter user email" />
							</Form.Item>

							<Form.Item>
								<div className="space-x-4">
									<Button type="primary" htmlType="submit" loading={loading}>
										Ghost User
									</Button>
								</div>
							</Form.Item>
						</Form>
					</Card>
				);
			case 'logs':
				return (
					<Card title="System Logs">
						<p>Logs page coming soon...</p>
					</Card>
				);
			default:
				return null;
		}
	};

	return (
		<div>
			<Navbar style={{}} />
			<Layout>
				{!isMobile && (
					<Sider width={200} theme="light" className="h-[calc(100dvh-80px)]" collapsible collapsed={collapsed} onCollapse={(value) => setCollapsed(value)} trigger={null}>
						<Menu mode="inline" selectedKeys={[selectedKey]} style={{height: '100%', borderRight: 0}} onClick={({key}) => setSelectedKey(key)} items={items} />
					</Sider>
				)}
				<Layout>
					{!isMobile && (
						<Button
							type="text"
							icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
							onClick={() => setCollapsed(!collapsed)}
							style={{
								fontSize: '16px',
								width: 64,
								height: 64,
							}}
						/>
					)}
					{isMobile && <Menu mode="horizontal" selectedKeys={[selectedKey]} onClick={({key}) => setSelectedKey(key)} items={items} />}
					<Content className="bg-white p-6 flex justify-center">{renderContent()}</Content>
				</Layout>
			</Layout>
		</div>
	);
}
