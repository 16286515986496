export const user_type = {
	EMPLOYER: 'Employer',
	JOB_SEEKER: 'Job-Seeker',
};

export function isEmployer(currentUser) {
	return currentUser?.profile?.user_type === user_type.EMPLOYER;
}

export function isJobSeeker(currentUser) {
	return currentUser?.profile?.user_type === user_type.JOB_SEEKER;
}

export function hasActiveFreeSubscription(currentUser) {
	return currentUser?.has_active_free_subscription;
}

export function userExists(currentUser) {
	return currentUser?.profile;
}

export function hasCreatedProfile(currentUser) {
	return currentUser?.profile?.first_name !== null;
}

export function isStaffMember(currentUser) {
	return currentUser?.email.endsWith('@permworks.com') || currentUser?.email.endsWith('@permhunt.com');
}

const allowedAdminEmails = ['elias@permhunt.com', 'tim@permhunt.com', 'eliaskhouryy@hotmail.com', 'sue@permworks.com', 'tim@permworks.com', 'elias@permworks.com'];
export const isAdmin = (currentUser) => allowedAdminEmails.includes(currentUser?.email);
