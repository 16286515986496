import React, {useState} from 'react';
import {Helmet} from 'react-helmet';
import {Form, Input, Button, Spin, Alert, Card, notification} from 'antd';
import {Alert as MuiAlert} from '@mui/material';
import Footer from '../../components/Footer/Footer';
import Navbar2 from '../../components/Navbars/Navbar2';
import {contactUsApi} from '../../api/contactUsApi';
import './ContactUs.scss';
import {permColors} from '../../utils/colors';
import ReCAPTCHA from 'react-google-recaptcha';

export default function ContactUs() {
	const [form] = Form.useForm();
	const [loading, setLoading] = useState(false);
	const [submitted, setSubmitted] = useState(false);
	const [captchaToken, setCaptchaToken] = useState(null);

	const contactUs = async (values) => {
		console.log(captchaToken);
		if (!captchaToken) {
			notification.error({
				message: 'Please complete the CAPTCHA',
				description: 'Please complete the CAPTCHA to submit your message.',
				duration: 5,
			});
			return;
		}
		setLoading(true);
		const {email, name, message} = values;
		const res = await contactUsApi(email, name, message, captchaToken);
		setLoading(false);
		if (res === true) {
			setSubmitted(true);
			form.resetFields();
			setCaptchaToken(null);
		}
		if (res.error === 'captcha') {
			notification.error({
				message: 'Error',
				description: res.message,
				duration: 5,
			});
			return;
		}
		if (res === false) {
			notification.error({
				message: 'Error',
				description: 'An error occurred while submitting your message. Please try again.',
				duration: 5,
			});
		}
	};

	const handleCaptchaChange = (token) => {
		setCaptchaToken(token);
	};

	return (
		<div className="height-setter bg-backgroundGreyBlue">
			<Helmet>
				<title>Contact Us | Permworks</title>
				<meta name="description" content="Get in touch with us. We'd love to hear from you. If you have any questions, feedback or suggestions, please let us know." />
			</Helmet>
			<Navbar2 />
			<div className="contact-us">
				<Card className="w-[580px] max-w-full mx-auto my-4">
					<Card className="mb-6">
						<h1 className="mb-2">Contact form</h1>
						<p className="text-sm text-charcoal ">If you have any questions, feedback or suggestions, please let us know.</p>
					</Card>
					<Form form={form} onFinish={contactUs} layout="vertical">
						<Form.Item label="Email Address" name="email" rules={[{required: true, message: 'Please input your email address', type: 'email'}]}>
							<Input placeholder="Email Address*" />
						</Form.Item>
						<Form.Item label="Name" name="name" rules={[{required: true, message: 'Please input your name'}]}>
							<Input placeholder="Your Name*" />
						</Form.Item>
						<Form.Item label="Message" name="message" rules={[{required: true, message: 'Please input your message'}]}>
							<Input.TextArea placeholder="Your Message*" />
						</Form.Item>
						{submitted && (
							<MuiAlert severity="success" sx={{my: 2}}>
								Thank you for submitting your inquiry. Our team will review and respond to your message promptly. For direct communication, you may reach us at hello@permworks.com.
							</MuiAlert>
						)}
						<Form.Item className="flex justify-center">
							<ReCAPTCHA sitekey="6Ld1ZqoqAAAAACEU1waR1sn5h1zInVi4mA7sXWPd" onChange={handleCaptchaChange} />
						</Form.Item>
						<Form.Item className="mb-0">
							<Button type="primary" htmlType="submit" disabled={submitted || loading} loading={loading} className="w-full">
								{submitted ? 'Submitted' : 'Submit'}
							</Button>
						</Form.Item>
					</Form>
				</Card>
			</div>
			<Footer backgroundColor={permColors.white} />
		</div>
	);
}
