import {useEffect, useRef} from 'react';
import {wsUrl} from '../../api/Urls';
import {getMessagesApi} from '../../api/messagingApi';

export const useWebSocket = ({currentUser, selectedConversation, setMessagesContent, setMessage}) => {
	const ws = useRef(null);

	useEffect(() => {
		if (currentUser?.id) {
			const token = localStorage.getItem('accessToken');
			ws.current = new WebSocket(`${wsUrl}/ws/notifications/?token=${token}`);

			ws.current.onopen = () => {
				console.log('WebSocket Connected Successfully');
				ws.current.send(
					JSON.stringify({
						type: 'connect',
						user_id: currentUser.id,
					})
				);
			};

			ws.current.onmessage = (event) => {
				console.log('Raw WebSocket message received');
				if (selectedConversation) {
					getMessagesApi(selectedConversation).then((res2) => {
						setMessagesContent(res2);
						setMessage('');
					});
				}
			};

			ws.current.onerror = (error) => {
				console.error('WebSocket connection error');
				setTimeout(() => {
					// Attempt to reconnect
				}, 5000);
			};

			ws.current.onclose = (event) => {
				console.log('WebSocket connection closed');
				if (event.code !== 1000) {
					setTimeout(() => {
						// Attempt to reconnect
					}, 5000);
				}
			};

			return () => {
				if (ws.current) {
					ws.current.close(1000, 'Component unmounting');
				}
			};
		}
	}, [currentUser?.id, selectedConversation, setMessagesContent, setMessage]);

	return ws;
};
