import React, {Fragment, useEffect, useState, useContext, useRef} from 'react';
import {Link} from 'react-router-dom';
import Hamburger from 'hamburger-react';
import './Navbar2.scss';
import {useNavigate} from 'react-router-dom';
import {NotificationContext, UserContext} from '../../utils/UserContext';
import {FullLogo, FullLogoSmall, FullLogoSmallNav} from '../Logos/Logo';
import Loading from '../Loaders/Loading';
import NotificationsIcon from '@mui/icons-material/Notifications';
import Badge from '@mui/material/Badge';
import {formatDate2} from '../../utils/DateFunctions';
import {getNotificationsApi, markAllAsReadApi} from '../../api/notificationsApi';
import {ADMIN_ROUTES, ROUTES} from '../../utils/ROUTES';
import {useMediaQuery} from '@material-ui/core';
import {isAdmin, user_type} from '../../utils/variables/userVariables';
import {Dashboard, LockOpen, LoginOutlined, LogoutOutlined, ManageSearch, PostAdd, Queue} from '@mui/icons-material';
import {permColors} from '../../utils/colors';
import {Button, Collapse, Drawer, Dropdown, Empty, List as Listy, Spin} from 'antd';
import {firebaseLogout} from '../../api/authApi';
import {useAtom} from 'jotai';
import {selectedAtom, unreadNotificationsAtom} from '../../utils/atoms';
import {wsUrl} from '../../api/Urls';
import {AppstoreAddOutlined, BellOutlined, DownOutlined, PlusOutlined, PlusSquareOutlined, RightOutlined, TeamOutlined, UserOutlined} from '@ant-design/icons';
export default function Navbar({style, backgroundColor = '#fffffe', boxShadow = 'rgba(0, 0, 0, 0.05) 0px 0px 0px 1px'}) {
	const navigate = useNavigate();
	const [isOpen, setOpen] = useState(false);
	const [menu, setMenu] = useState(false);
	const [auth, setAuth] = useState(false);
	const [dropdownVisible, setDropdownVisible] = useState(false);
	const logo = FullLogoSmallNav();
	const [loading, setLoading] = useState(false);
	const [currentUser, setCurrentUser] = useContext(UserContext);
	const [profileImage, setProfileImage] = useState('/userIcon.png');
	const [notifications, setNotifications] = useContext(NotificationContext);
	const [unreadNotifications, setUnreadNotifications] = useAtom(unreadNotificationsAtom);
	const isMobile = useMediaQuery('(max-width: 930px)');
	const [selected, setSelected] = useAtom(selectedAtom);
	const URL_PATHNAME = window.location.pathname;
	const ws = useRef(null);
	const [howItWorksVisible, setHowItWorksVisible] = useState(false);
	const [notificationLoading, setNotificationLoading] = useState(false);

	const links = [
		{path: ROUTES.HOME, label: 'Home'},
		{path: ROUTES.BROWSE_JOBS, label: 'Browse jobs'},
		{path: ROUTES.ABOUT_US, label: 'About us'},
		{path: ROUTES.PRICING, label: 'Pricing'},
		{
			label: 'How it works',
			isDropdown: true,
			items: [
				{
					label: 'For employers',
					path: ROUTES.HOW_IT_WORKS,
					icon: <UserOutlined />,
				},
				{
					label: 'For job seekers',
					path: ROUTES.HOW_IT_WORKS_JOB_SEEKER,
					icon: <TeamOutlined />,
				},
			],
		},
		// {path: ROUTES.HOW_IT_WORKS, label: 'How it works'},
		{path: ROUTES.CONTACT_US, label: 'Contact us'},
		...(isAdmin(currentUser) ? [{path: ADMIN_ROUTES.ADMIN, label: 'Admin'}] : []),
		{path: ROUTES.POST_JOB, label: 'Post job'},
	];

	const [show, setShow] = useState(false);
	const handleClose = () => setShow(false);

	const handleShow = async () => {
		if (unreadNotifications === 0) {
			setShow(true);
			setNotificationLoading(false);
			return;
		}

		setNotificationLoading(true);
		try {
			await markAllAsReadApi();
			setUnreadNotifications(0);
		} catch (error) {
			console.error(error);
		} finally {
			setShow(true);
			setNotificationLoading(false);
		}
	};

	const fetchNotifications = async () => {
		try {
			const response = await getNotificationsApi();
			if (response) {
				setNotifications(response);
				const unreadCount = response.filter((notif) => !notif.read).length;
				setUnreadNotifications(unreadCount);
			}
		} catch (error) {
			console.error('Error fetching notifications:', error);
		}
	};

	useEffect(() => {
		if (currentUser?.profile) {
			setAuth(true);
			if (currentUser?.profile?.profile_image) {
				const img = new Image();
				img.src = currentUser.profile.profile_image;
				img.onload = () => setProfileImage(currentUser.profile.profile_image);
			}
		}
	}, [currentUser]);

	useEffect(() => {
		console.log('auth', auth);
		if (auth) {
			// Get the user ID from auth
			const userId = currentUser.id; // Changed from profile.id to match your backend user model

			console.log('Starting WebSocket connection...', userId);

			// Include authentication token in the WebSocket URL
			const token = localStorage.getItem('accessToken'); // Or however you store your JWT
			ws.current = new WebSocket(`${wsUrl}/ws/notifications/?token=${token}`);
			// ws.current = new WebSocket(`ws://permworks-api.com/ws/notifications/?token=${token}`);

			ws.current.onopen = () => {
				console.log('WebSocket Connected Successfully');
				// Optionally send an initial message to identify the user
				ws.current.send(
					JSON.stringify({
						type: 'connect',
						user_id: userId,
					})
				);
			};

			ws.current.onmessage = (event) => {
				console.log('Raw WebSocket message received');
				fetchNotifications();
			};

			ws.current.onerror = (error) => {
				console.error('WebSocket connection error:');
				console.error('WebSocket Error:');
				console.log('WebSocket State:');
				console.log('WebSocket URL:');
				// Implement reconnection logic if needed
				setTimeout(() => {
					// Attempt to reconnect
				}, 5000);
			};

			ws.current.onclose = (event) => {
				console.log('WebSocket connection closed:');
				// Implement reconnection logic if needed
				if (event.code !== 1000) {
					// Normal closure
					setTimeout(() => {
						// Attempt to reconnect
					}, 5000);
				}
			};

			// Cleanup on unmount
			return () => {
				if (ws.current) {
					ws.current.close(1000, 'Component unmounting');
				}
			};
		}
	}, [auth, currentUser]);

	useEffect(() => {
		if (currentUser?.profile) setAuth(true);
	}, [currentUser]);

	async function logout() {
		try {
			await new Promise((resolve) => {
				localStorage.setItem('currentUser', '');
				localStorage.setItem('accessToken', '');
				localStorage.setItem('token', '');
				setCurrentUser('');
				setNotifications([]);
				setUnreadNotifications(0);
				firebaseLogout();
				resolve();
			});
			setLoading(true);
			window.location.href = ROUTES.AUTHENTICATE;
		} catch (error) {
			console.error(error);
		}
	}

	const handleNotificationClick = (notif) => {
		switch (true) {
			case notif.message.toLowerCase().includes('new application '):
				navigate(ROUTES.APPLICANTS.replace(':jobId', notif?.job_offer?.job));
				break;
			case notif.message.toLowerCase().includes('new message'):
				setSelected('inbox');
				navigate(ROUTES.DASHBOARD);
				break;
			case notif.message.toLowerCase().includes('new job offer'):
				navigate(ROUTES.APPLICANTS.replace(':jobId', notif?.job_offer?.job));
				break;
			default:
				break;
		}
	};

	const items = [
		{
			key: 'profile',
			label: (
				<div className="flex items-center gap-2">
					<img className="dummyIcon" src={profileImage} alt="Icon" style={{cursor: 'default', width: '30px', height: '30px'}} />
					<div>
						<p className=" max-w-[200px] truncate mb-0 text-sm " style={{color: permColors.darkCharcoal}}>
							{currentUser?.profile?.first_name ? `${currentUser?.profile?.first_name} ${currentUser?.profile?.last_name}` : currentUser?.email}
						</p>
						{currentUser?.profile?.user_type === user_type.JOB_SEEKER && (
							<p style={{color: permColors.charcoal}} className="mb-0 text-sm">
								{currentUser?.profile?.freelancer_profile_title}
							</p>
						)}
					</div>
				</div>
			),
			disabled: true,
			hover: false,
		},
		{
			key: 'view-profile',
			label: (
				<Button className="ButtonLinkBlue min-h-fit w-full m-0" onClick={() => navigate(ROUTES.EDIT_PROFILE)}>
					View Profile
				</Button>
			),
			disabled: true,
		},
		{
			key: 'divider-1',
			type: 'divider',
		},
		{
			key: 'dashboard',
			label: 'Dashboard',
			icon: <Dashboard style={{fontSize: '1.2em'}} />,
			onClick: () => navigate(ROUTES.DASHBOARD),
		},
		// {
		// 	key: 'eordashboard',
		// 	label: 'EOR Dashboard',
		// 	icon: <Dashboard style={{fontSize: '1.2em'}} />,
		// 	onClick: () => navigate(ROUTES.EOR_DASHBOARD),
		// },
		currentUser?.profile?.user_type === user_type.EMPLOYER && {
			key: 'post-job',
			label: 'Post Job',
			icon: <PostAdd style={{fontSize: '1.2em'}} />,
			onClick: () => navigate(ROUTES.POST_JOB),
		},
		{
			key: 'browse-jobs',
			label: 'Browse Jobs',
			icon: <ManageSearch style={{fontSize: '1.2em'}} />,
			onClick: () => navigate(ROUTES.BROWSE_JOBS),
		},
		{
			key: 'divider-2',
			type: 'divider',
		},
		{
			key: 'logout',
			label: 'Logout',
			icon: <LogoutOutlined style={{fontSize: '1.2em'}} />,
			onClick: logout,
		},
	].filter(Boolean); // Filter out any falsy values from conditional rendering

	const guestLinks = () => {
		return (
			<>
				{!isMobile && (
					<div>
						<Button type="primary" icon={<LoginOutlined className="text-base" />} onClick={() => navigate(ROUTES.AUTHENTICATE)}>
							Login
						</Button>
					</div>
				)}
				{isMobile && (
					<div className={'visible mt-2 flex flex-col gap-2 w-full'}>
						{links.map((link) => (
							<div key={link.path || link.label}>
								{link.isDropdown ? (
									<Collapse
										ghost
										items={[
											{
												key: '1',
												label: <span className="text-sm text-deepBlue">{link.label}</span>,

												children: (
													<div className="flex flex-col gap-2 pl-4">
														{link.items.map((item, index) => (
															<Link
																key={index}
																to={item.path}
																className={(URL_PATHNAME === item.path ? 'bg-backgroundGrey w-fit' : '') + ' text-sm p-2 text-deepBlue rounded-md'}>
																{item.label}
															</Link>
														))}
													</div>
												),
											},
										]}
									/>
								) : (
									<Link className={(URL_PATHNAME === link.path ? 'bg-backgroundGrey w-full' : '') + ' text-sm p-2 text-deepBlue rounded-md'} to={link.path}>
										{link.label}
									</Link>
								)}
							</div>
						))}
						<Button type="primary" icon={<LoginOutlined className="text-base" />} onClick={() => navigate(ROUTES.AUTHENTICATE)}>
							Login
						</Button>
					</div>
				)}
			</>
		);
	};

	const authLinks = () => {
		const authRoutes = [
			{path: ROUTES.EDIT_PROFILE, label: `Hello ${currentUser?.profile?.first_name || ''}!`},
			{path: ROUTES.HOME, label: 'Home'},
			{path: ROUTES.DASHBOARD, label: 'Dashboard'},
			{path: ROUTES.BROWSE_JOBS, label: 'Browse jobs'},
			// {path: ROUTES.EOR_DASHBOARD, label: 'EOR Dashboard'},
			{
				label: 'How it works',
				isDropdown: true,
				items: [
					{
						label: 'For employers',
						path: ROUTES.HOW_IT_WORKS,
						icon: <UserOutlined />,
					},
					{
						label: 'For job seekers',
						path: ROUTES.HOW_IT_WORKS_JOB_SEEKER,
						icon: <TeamOutlined />,
					},
				],
			},
			{path: ROUTES.ABOUT_US, label: 'About us'},
			{path: ROUTES.PRICING, label: 'Pricing'},
			{path: ROUTES.CONTACT_US, label: 'Contact us'},
			{path: ROUTES.POST_JOB, label: 'Post Job'},
			...(isAdmin(currentUser) ? [{path: ADMIN_ROUTES.ADMIN, label: 'Admin'}] : []),
			{path: '#', label: 'Logout', onClick: logout},
		];

		return (
			<Fragment>
				{!isMobile && (
					<Dropdown
						menu={{
							items,
						}}
						placement="bottomRight">
						<img className="dummyIcon" src={profileImage} alt="Icon" onClick={() => setDropdownVisible(!dropdownVisible)} />
					</Dropdown>
				)}
				{isMobile && (
					<div className={'visible mt-2 flex flex-col gap-2 w-full'}>
						{authRoutes.map((route) => (
							<div key={route.path || route.label}>
								{route.isDropdown ? (
									<Collapse
										ghost
										items={[
											{
												key: '1',
												label: <span className="text-sm text-deepBlue">{route.label}</span>,
												children: (
													<div className="flex flex-col gap-2 pl-4">
														{route.items.map((item, index) => (
															<Link
																key={index}
																to={item.path}
																className={(URL_PATHNAME === item.path ? 'bg-backgroundGrey w-fit' : '') + ' text-sm p-2 text-deepBlue rounded-md'}>
																{item.label}
															</Link>
														))}
													</div>
												),
											},
										]}
									/>
								) : (
									<Link
										className={(URL_PATHNAME === route.path ? 'bg-backgroundGrey w-full' : '') + ' text-sm p-2 flex text-deepBlue items-center gap-2 rounded-md'}
										to={route.path}
										onClick={route.onClick}>
										{route.path === ROUTES.EDIT_PROFILE && <img className="dummyIcon" src={profileImage} alt="Icon" />}
										{route.label}
									</Link>
								)}
							</div>
						))}
					</div>
				)}
			</Fragment>
		);
	};

	return (
		<nav className="nav2" style={{backgroundColor: backgroundColor, boxShadow: boxShadow, ...style}}>
			<div>
				<div>
					<ul className="bigNav">
						<div className="inline2">
							<li>{logo}</li>
							<div className="flex items-end gap-4 2xl:gap-6 ml-7 2xl:ml-12 text-sm rounded-lg p-2 pl-3 shadow-border">
								{links.map((link) => (
									<li key={link.path || link.label}>
										{link.isDropdown ? (
											<Dropdown
												onVisibleChange={() => setHowItWorksVisible(!howItWorksVisible)}
												menu={{
													items: link.items.map((item, index) => ({
														key: index,
														label: (
															<Link to={item.path} className={(URL_PATHNAME === item.path ? 'font-semibold' : '') + ' font-normal text-sm text-deepBlue rounded-md'}>
																{item.label}
															</Link>
														),
														icon: item.icon,
													})),
												}}>
												<span className={'cursor-pointer font-normal text-deepBlue flex items-center gap-2 ' + (URL_PATHNAME === link.path ? 'font-bold' : '')}>
													<RightOutlined style={{fontSize: '0.8em'}} className={' ' + (howItWorksVisible ? 'rotate-90 transition-all duration-300' : '')} />
													{link.label}
												</span>
											</Dropdown>
										) : link.path !== ROUTES.POST_JOB ? (
											<Link className={URL_PATHNAME === link.path ? 'font-bold' : 'font-normal'} to={link.path}>
												{link.label}
											</Link>
										) : (
											<Button icon={<Queue className="text-base" />} type="primary" href={ROUTES.POST_JOB} className="text-white">
												Post job
											</Button>
										)}
									</li>
								))}
							</div>
						</div>
						<div className="text-sm flex items-center gap-4">
							{auth && (
								<li>
									<Badge badgeContent={unreadNotifications} color="error">
										{notificationLoading ? <Spin /> : <NotificationsIcon onClick={handleShow} className="notificationsIcon" />}
									</Badge>
								</li>
							)}
							<div className="right-links">{auth ? authLinks() : guestLinks()}</div>
						</div>
					</ul>
					<div className="smallNav">
						<span onClick={() => (menu ? setMenu(false) : setMenu(true))} className="flex items-center justify-between gap-2">
							<Hamburger size={20} color={permColors.darkBlue} toggled={isOpen} toggle={setOpen} className="hamburger2 w-[15px]" />
							<div>{logo}</div>
						</span>
						{auth && (
							<div className="rightSide">
								<Badge badgeContent={unreadNotifications} color="error">
									<NotificationsIcon onClick={handleShow} className="notificationsIcon" />
								</Badge>
							</div>
						)}
					</div>
					{menu && isMobile && (
						<div className="text-base text-left">
							<ul>{auth ? authLinks() : guestLinks()}</ul>
						</div>
					)}
				</div>
			</div>
			{loading && <Loading />}
			<Drawer title="Notifications" placement="right" onClose={handleClose} visible={show} width={300} bodyStyle={{padding: 0}}>
				{notifications?.length > 0 ? (
					<Listy
						itemLayout="horizontal"
						dataSource={notifications}
						renderItem={(notif) => (
							<Listy.Item className="cursor-pointer px-4 py-3 duration-300 hover:bg-backgroundGreyBlue" onClick={() => handleNotificationClick(notif)}>
								<Listy.Item.Meta avatar={<BellOutlined style={{color: permColors.linkBlue}} />} title={notif.message} description={formatDate2(notif.created_at)} />
							</Listy.Item>
						)}
					/>
				) : (
					<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No notifications yet. Check back later." />
				)}
			</Drawer>
		</nav>
	);
}
