import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import {FullLogo, FullLogoSmallNav} from '../Logos/Logo';
import {otherPageLinks, socialMediaLinks} from './footer_data';
import {permColors} from '../../utils/colors';
import {Button, Form, Input, message} from 'antd';
import {ExportOutlined} from '@ant-design/icons';

export default function Footer({backgroundColor = permColors.backgroundLightCream}) {
	const [loading, setLoading] = useState(false);
	const [form] = Form.useForm();

	const handleSubmit = async (values) => {
		setLoading(true);
		await new Promise((resolve) => setTimeout(resolve, 1000));
		setLoading(false);
		if (values.email) {
			message.success('Thanks for subscribing, we will be in touch shortly.');
			form.resetFields();
		} else {
			message.error('Please enter an email address.');
		}
	};

	return (
		<footer className="py-10 px-3 sm:pt-16 lg:pt-24 mt-auto" style={{backgroundColor}}>
			<div className="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">
				<div className="grid grid-cols-2 md:col-span-3 lg:grid-cols-12 gap-y-16 gap-x-12">
					<div className="col-span-2 md:col-span-3 lg:col-span-4 lg:pr-8">
						<FullLogoSmallNav />
						<p className="text-base leading-relaxed mt-7" style={{color: permColors.grey}}>
							Permworks is a jobs board connecting Filipino talent to employers worldwide.
						</p>

						<ul className="flex items-center space-x-3 mt-9">
							{socialMediaLinks.map(({href, icon}, index) => (
								<li key={index}>
									<a
										href={href}
										target="_blank"
										rel="noopener noreferrer"
										className="flex items-center justify-center text-white transition-all duration-200 rounded-full w-7 h-7 hover:bg-blue-600 focus:bg-blue-600"
										style={{backgroundColor: permColors.charcoal}}>
										{icon}
									</a>
								</li>
							))}
						</ul>
					</div>

					{otherPageLinks.map((column, columnIndex) => (
						<div key={columnIndex} className="lg:col-span-2">
							<p className="text-sm font-semibold tracking-widest uppercase" style={{color: permColors.grey}}>
								{columnIndex === 0 ? 'Company' : columnIndex === 1 ? 'Resources' : 'Legal'}
							</p>

							<ul className="mt-6 space-y-4">
								{column.map((link, linkIndex) => (
									<li key={linkIndex}>
										{link.external ? (
											<a
												href={link.to}
												target="_blank"
												rel="noopener noreferrer"
												className="text-sm transition-all duration-200 hover:text-blue-600 focus:text-blue-600"
												style={{color: permColors.grey}}>
												{link.label}
												<ExportOutlined className="text-xs ml-2" />
											</a>
										) : (
											<Link to={link.to} className="flex text-sm transition-all duration-200 hover:text-blue-600 focus:text-blue-600" style={{color: permColors.grey}}>
												{link.label}
											</Link>
										)}
									</li>
								))}
							</ul>
						</div>
					))}
				</div>

				<hr className="mt-16 mb-10 border-gray-200" />

				<p className="text-sm text-center" style={{color: permColors.grey}}>
					© Copyright {new Date().getFullYear()}, All Rights Reserved by Permworks
				</p>
			</div>
		</footer>
	);
}
